import React from "react";
import PropTypes from "prop-types";

import { onSignIn, isAuthenticated } from "../utils/auth/auth";

/**
 * PrivateRoute(component,...rest) is used to restrict navigation to components
 * @param {Component, ...rest}
 */
export const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!isAuthenticated()) {
    onSignIn();
    return null;
  }
  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export default PrivateRoute;
