import React, { useState, useRef } from "react";
import { PropTypes } from "prop-types";

import Strings from "../../../resources/strings";
import AssetsContentCard from "../../Cards/CapabilitiesContentCard/CapabilitiesContentCard";
import PageModal from "../../PageModal/PageModal";
import AssetCategoryDetails from "../../Pages/CapabilitiesCategoryDetails/CapabilitiesCategoryDetails";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { AssetsContentWrapper, Wrapper } from "./style";
/**
 * AssetsContent will provides different assets details of provider
 * @param {Object} capabilities
 * @param {Array} capabilitiesList
 * @param {Array} providers
 * @param {Array} capabilitiesSubCategoryLookupIds
 * @example
 * AssetsContent()
 */
const AssetsContent = ({
  capabilities,
  capabilitiesList,
  providers,
  capabilitiesSubCategoryLookupIds
}) => {
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCapability, setSelectedCapability] = useState(null);

  const handleModal = selectedCapability => {
    selectedCapability && setSelectedCapability(selectedCapability);
    setShowModal(true);
  };
  const closeModal = () => {
    modalRef.current.classList.remove("fade");
    setShowModal(false);
  };

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Wrapper>
            <AssetsContentWrapper>
              {capabilitiesList && capabilities
                ? capabilitiesList.map(
                    ({ Title }) =>
                      capabilities[Title] &&
                      capabilities[Title].length > 0 && (
                        <AssetsContentCard
                          title={Title}
                          content={
                            capabilities[Title].length +
                            " " +
                            (capabilities[Title].length > 1
                              ? dictionary?.subAssets || Strings.subAssets
                              : dictionary?.subAsset || Strings.subAsset)
                          }
                          key={Title}
                          handleModal={() => handleModal(capabilities[Title])}
                          data-test="capabilities-modal"
                        />
                      )
                  )
                : null}
            </AssetsContentWrapper>
            <PageModal
              handleModal={handleModal}
              showModal={showModal}
              closeModal={closeModal}
              data-test="closeModal"
              modalRef={modalRef}>
              <AssetCategoryDetails
                selectedCapability={selectedCapability}
                closeModal={closeModal}
                capabilitiesCategory={capabilitiesList}
                providers={providers}
                capabilitiesSubCategoryLookupIds={
                  capabilitiesSubCategoryLookupIds
                }
              />
            </PageModal>
          </Wrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

AssetsContent.propTypes = {
  capabilities: PropTypes.object,
  capabilitiesList: PropTypes.array,
  providers: PropTypes.array,
  capabilitiesSubCategoryLookupIds: PropTypes.array
};

export default AssetsContent;
