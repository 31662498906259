import React, {
  Fragment,
  useContext,
  useRef,
  useState,
  useEffect
} from "react";

import { Helmet } from "react-helmet";

import { PageContainer } from "../../../styles/commonStyles";
import PageModal from "../../PageModal/PageModal";
import AssetCategoryDetails from "../../Pages/CapabilitiesCategoryDetails/CapabilitiesCategoryDetails";
import Strings from "../../../resources/strings";
import ExploreBannerCard from "../../Cards/ExploreBannerCard/ExploreCard";
import CardGrid from "../../CommonComponents/CardGrid/CardGrid";
import ProviderCard from "../../Cards/ProviderCard/ProviderCard";
import AssetCard from "../../Cards/CapabilitiesCard/CapabilitiesCard";
import TitleDropdown from "../../CommonComponents/TitleDropdown/TitleDropdown";
import CaseStudyDetails from "../CaseStudyDetails/CaseStudyDetails";
import ExploreCategory from "../../ExploreCategory/ExploreCategory";
import QuickTour from "./QuickTour";
import { StateContext } from "../../../providers/StateContext";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { getApiCall, postApiCall } from "../../../services/api";
import { getUserId } from "../../../utils/commonUtils";
import { LoaderWrap } from "../../Loader/style";
import Loader from "../../Loader/Loader";

/**
 * Used to get the Explore landing page layout
 * @returns Explore()
 */
export const Explore = () => {
  const state = useContext(StateContext);
  const { providers, categoryCount, capabilitiesCategory, guideCards } = state;
  const capabilities = state.uniqueCapabilities;
  const category = state.providersCategory;
  const capabilitiesCategoryMaster = state.lookups.capabilitiesCategory;
  const capabilitiesSubCategoryLookupIds =
    state.lookups.capabilitiesSubCategoryLookupId;
  const featuredCaseStudy = state.caseStudy.featuredCaseStudy;
  const [selectedCapability, setSelectedCapability] = useState(null);
  const [selectedCaseStudy, setSelectedCaseStudy] = useState(null);

  const modalRef = useRef(null);
  const slider = React.createRef();
  const [showModal, setShowModal] = useState(false);
  const [assetPopUp, setAssetPopUp] = useState(false);
  const [showQuickTour, setShowQuickTour] = useState(false);
  const [popularProviders, setPopularProviders] = useState([]);
  const [popularCapabilities, setPopularCapabilities] = useState([]);

  const handleModal = (type, selectedData) => {
    setAssetPopUp(type === Strings.assets);
    if (type === Strings.assets) {
      selectedData && setSelectedCapability(selectedData);
    } else if (type === Strings.caseStudies) {
      selectedData && setSelectedCaseStudy(selectedData);
    }
    showModal && closeModal();
    setTimeout(() => {
      setShowModal(true);
    }, 300);
  };
  const closeModal = () => {
    modalRef.current.classList.remove("fade");
    setShowModal(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    showModal ? slider?.current?.slickPause() : slider?.current?.slickPlay();
  }, [showModal, slider]);

  useEffect(() => {
    let loginStatus = sessionStorage.getItem("loggingIn");
    if (loginStatus === "true") {
      getApiCall.getUserInformation(getUserId()).then(response => {
        if (response?.guideCompleted === false) {
          setShowQuickTour(true);
        }
      });
    }

    let popularProvidersData = [];
    let popularCapabilitiesData = [];
    getApiCall.getPopularCards(Strings.providers).then(response => {
      response?.length > 0 &&
        response.forEach(provider => {
          popularProvidersData.push(provider.listid + provider.itemid);
        });
      let filteredProviders = providers.filter(provider =>
        popularProvidersData.includes(provider?.listId + provider?.id)
      );
      let filteredPopularProviders =
        filteredProviders.length < 3
          ? [
              ...filteredProviders,
              ...providers.slice(0, 3 - filteredProviders.length)
            ]
          : [...filteredProviders];

      setPopularProviders(filteredPopularProviders);
    });
    getApiCall.getPopularCards(Strings.capabilities).then(response => {
      response?.length > 0 &&
        response.forEach(capability => {
          popularCapabilitiesData.push(capability?.listid + capability?.itemid);
        });
      let filteredCapabilities = capabilities.filter(capability =>
        popularCapabilitiesData.includes(capability.listId + capability.id)
      );
      let filteredPopularCapabilities =
        filteredCapabilities.length < 3
          ? [
              ...filteredCapabilities,
              ...capabilities.slice(0, 3 - filteredCapabilities.length)
            ]
          : [...filteredCapabilities];

      setPopularCapabilities(filteredPopularCapabilities);
    });
  }, [providers, capabilities]);

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Explore | SBG-FRBB</title>
            </Helmet>
            <QuickTour guideCards={guideCards} showQuickTour={showQuickTour} />
            <PageContainer>
              {popularProviders?.length > 0 &&
              popularCapabilities?.length > 0 ? (
                <Fragment>
                  <TitleDropdown
                    title={
                      dictionary?.explore || Strings.explore
                    }></TitleDropdown>
                  <ExploreBannerCard
                    data-test="explore-banner-card"
                    handleModal={handleModal}
                    featuredCaseStudy={featuredCaseStudy}
                    providers={providers}
                    slider={slider}></ExploreBannerCard>
                  {popularProviders && (
                    <CardGrid
                      isScroll={true}
                      title={
                        dictionary?.popularProviders || Strings.popularProviders
                      }
                      linkText={dictionary?.providers || Strings.providers}
                      redirectTo="/auth/providers">
                      {popularProviders.map((providerData, index) => (
                        <ProviderCard
                          headingBgColor={true}
                          key={index}
                          provider={providerData}
                          redirectTo={
                            "/auth/providers/details?id=" + providerData?.id
                          }></ProviderCard>
                      ))}
                    </CardGrid>
                  )}
                  {popularCapabilities && (
                    <CardGrid
                      isScroll={true}
                      title={dictionary?.popularAssets || Strings.popularAssets}
                      linkText={dictionary?.assets || Strings.assets}
                      redirectTo="/auth/capabilities">
                      {popularCapabilities.map((capabilitiesData, index) => (
                        <AssetCard
                          key={index}
                          providers={providers}
                          capabilitiesCategory={capabilitiesCategoryMaster}
                          handleModal={() => {
                            postApiCall.postPopularCards({
                              type: Strings.capabilities,
                              createdTime: capabilitiesData?.CreatedTime,
                              userId: getUserId(),
                              clickedTime: Date.now(),
                              listId: capabilitiesData?.listId,
                              itemId: capabilitiesData?.id
                            });
                            handleModal(
                              dictionary?.assets || Strings.assets,
                              capabilitiesCategory[
                                capabilitiesData.ProvidersLookupId
                              ][capabilitiesData.Category]
                            );
                          }}
                          capabilities={capabilitiesData}></AssetCard>
                      ))}
                    </CardGrid>
                  )}
                  <CardGrid
                    title={
                      dictionary?.ExploreCategoryTitle ||
                      Strings.ExploreCategoryTitle
                    }>
                    {category.map((category, index) => (
                      <ExploreCategory
                        key={index}
                        title={category.Title}
                        categoryCount={
                          categoryCount && categoryCount[category.Title]
                        }
                        redirectTo={
                          "/auth/providers?filter=" + category.Title
                        }></ExploreCategory>
                    ))}
                  </CardGrid>
                </Fragment>
              ) : (
                <LoaderWrap>
                  <Loader animation="animateToCenter" />
                </LoaderWrap>
              )}
            </PageContainer>
            <PageModal
              filter={assetPopUp ? "" : "true"}
              hideBtn={!assetPopUp && true}
              handleModal={handleModal}
              showModal={showModal}
              closeModal={closeModal}
              modalRef={modalRef}>
              {assetPopUp ? (
                <AssetCategoryDetails
                  selectedCapability={selectedCapability}
                  closeModal={closeModal}
                  capabilitiesSubCategoryLookupIds={Object.values(
                    capabilitiesSubCategoryLookupIds
                  )}
                  capabilitiesCategory={capabilitiesCategoryMaster}
                  providers={providers}
                />
              ) : (
                <CaseStudyDetails
                  closeModal={closeModal}
                  handleModal={handleModal}
                  type={dictionary?.explore || Strings.explore}
                  selectedCaseStudy={selectedCaseStudy}
                />
              )}
            </PageModal>
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  );
};
