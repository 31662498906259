import styled from "styled-components";

import { CardSubDescription, CardSubTitle } from "../../../styles/commonStyles";

export const CaseStudyCardWrapper = styled.div`
  width: 280px;
  margin: 12px 20px 25px 0;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColor.white};
  box-shadow: ${props => props.theme.shadow.card};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 334px;
    margin: 12px 0 25px;
    display: inline-block;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    width: 374px;
    margin: 12px 10px 4px 0;
    display: inline-block;
  }
  @media ${props => props.theme.breakPoints.xlMediumDesktopDevice} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media ${props => props.theme.breakPoints.xlDesktopDevice} {
    &:nth-child(3n) {
      margin-right: 20px;
    }
  }
  @media ${props => props.theme.breakPoints.extraLargeDesktopDevice} {
    &:nth-child(3n) {
      margin-right: 20px;
    }
  }
`;

export const CaseStudyCardImageWrapper = styled.div`
  background: url(${props => props.bannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  min-height: 169px;
  padding: 16px 13px 0 20px;
  box-sizing: border-box;
  color: ${props => props.theme.fontColor.white};
  font-size: ${props => props.theme.fontSize.tertiary};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const CaseStudyCardSubDescription = styled(CardSubDescription)`
  min-height: 78px;
  padding: 14px 36px 24px 20px;
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.description};
`;

export const CaseStudyCardContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    height: unset;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    height: unset;
  }
`;

export const CaseStudyCardDate = styled(CardSubTitle)`
  font-size: ${props => props.theme.fontSize.tertiary};
  line-height: ${props => props.theme.lineHeight.normal};
  padding: 0 36px 24px 20px;
`;
