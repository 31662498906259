import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";

import Strings from "../../../resources/strings";
import Images from "../../../resources/images";
import QuickTourModal from "../../ModalPopup/ModalPopup";
import QuickTourCard from "../../Cards/QuickTourCard/QuickTourCard";
import IconTextCard from "../../Cards/IconTextCard/IconTextCard";
import QuickTourStartCard from "../../Cards/QuickTourStartCard/QuickTourStartCard";
import { getRefactoredImageUrl, getUserId } from "../../../utils/commonUtils";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { postApiCall } from "../../../services/api";

/**
 * QuickTour Component will introduce new user to this website
 * @param {Array} guideCards
 * @param {boolean} showQuickTour
 * @example
 * QuickTour()
 */
const QuickTour = ({ guideCards, showQuickTour }) => {
  const quickTourModalRef = useRef(null);
  const [showQuickTourModal, setShowQuickTourModal] = useState(false);
  const [currentChildIndex, setCurrentChildIndex] = useState(0);
  const [contentChildIndex, setContentChildIndex] = useState(0);
  const [quickTourStartCard, setQuickTourStartCard] = useState(null);
  const [quickTourCard, setQuickTourCard] = useState(null);

  const handleCloseModal = () => {
    quickTourModalRef.current.classList.remove("fade");
    setShowQuickTourModal(false);
  };

  const handleTour = () => {
    currentChildIndex === 0 &&
      setCurrentChildIndex(1) &&
      setContentChildIndex(0);

    currentChildIndex === 1 &&
      contentChildIndex === 2 &&
      updateUserInformation();

    currentChildIndex === 1 &&
      contentChildIndex !== 2 &&
      setContentChildIndex(contentChildIndex + 1);
  };

  const handlePrevTour = () => {
    contentChildIndex === 0
      ? setCurrentChildIndex(currentChildIndex - 1)
      : setContentChildIndex(contentChildIndex - 1);
  };

  const changeKey = () => {
    setShowQuickTourModal(true);
    window.sessionStorage.setItem("loggingIn", false);
  };

  useEffect(() => {
    setQuickTourStartCard(
      guideCards.filter(card => card?.Appearance === null)[0]
    );
    setQuickTourCard(guideCards.filter(card => card?.Appearance !== null));
    showQuickTour && changeKey();
    let loginTime = window.sessionStorage.getItem("LoginTime");
    if (loginTime) {
      postApiCall
        .postUserInformation({
          userId: getUserId(),
          loginTime: parseInt(loginTime)
        })
        .then(res => {
          window.sessionStorage.removeItem("LoginTime");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQuickTour]);

  const updateUserInformation = () => {
    postApiCall
      .postUserInformation({
        userId: getUserId(),
        guideCompleted: true
      })
      .then(res => {});
    setCurrentChildIndex(currentChildIndex + 1);
  };

  return (
    <QuickTourModal
      data-test="quick-tour-modal"
      showModal={showQuickTourModal}
      closeModal={handleCloseModal}
      modalRef={quickTourModalRef}
      currentChildIndex={currentChildIndex}
      contentChildIndex={contentChildIndex}
      isQuickTour={true}>
      <QuickTourStartCard
        data-test="quick-tour-start-card"
        closeModal={handleCloseModal}
        handleStartTour={handleTour}
        quickTourStartCard={quickTourStartCard}
      />
      <Fragment>
        {quickTourCard &&
          quickTourCard?.map((data, index) => (
            <QuickTourCard
              data-test="quick-tour-card"
              key={index}
              img={getRefactoredImageUrl(data?.Image?.Url)}
              closeModal={handleCloseModal}
              title={data?.Title}
              description={data?.Description}
              currentCardNo={index + 1}
              totalCards={quickTourCard?.length}
              handlePrevTour={handlePrevTour}
              handleTour={handleTour}
              prevBtnDisable={index === 0}
            />
          ))}
      </Fragment>
      <DictionaryContext.Consumer>
        {({ dictionary }) => {
          return (
            <IconTextCard
              handleCloseModal={handleCloseModal}
              title={dictionary?.finishedTour || Strings.finishedTour}
              image={Images.SuccessImg}
              description={dictionary?.startExploring || Strings.startExploring}
              width="75px"
              height="75px"
              mobHeight="75px"
              mobWidth="75px"
            />
          );
        }}
      </DictionaryContext.Consumer>
    </QuickTourModal>
  );
};

QuickTour.propTypes = {
  guideCards: PropTypes.array,
  showQuickTour: PropTypes.bool
};

export default QuickTour;
