import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ContactTable from "../../Tables/ContactTable/ContactTable";
import Strings from "../../../resources/strings";
import { DetailsInformationTitle } from "./../../../styles/commonStyles";
import { DictionaryContext } from "../../../providers/DictionaryContext";

/**
 * ContactContent component will provides content when Contact tab is active in ProviderDetails Page
 * @param {Object} businessTable
 * @example
 * ContactContent()
 */
const ContactContent = ({ businessTable }) => {
  const tableHeading = [Strings.name, Strings.email];

  return businessTable.length ? (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            <DetailsInformationTitle>
              {dictionary?.businessEnquiry || Strings.businessEnquiry}
            </DetailsInformationTitle>
            <ContactTable
              tableHeading={tableHeading}
              tableData={businessTable}
            />
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  ) : (
    ""
  );
};

ContactContent.propTypes = {
  businessTable: PropTypes.array
};

export default ContactContent;
