import React, { useState } from "react";
import PropTypes from "prop-types";

import LineSeparator from "../LineSeparator/LineSeparator";
import Strings from "../../../resources/strings";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  TabsWrapper,
  TabsList,
  TabListItem,
  Tab,
  TabContentWrapper,
  TabContent
} from "./style";

/**
 * CustomTab component is a common tab component
 * @param {Array} tabTitle - Provides title for every tab
 * @param {Array} tabContent - Provides content with respect to activeTab
 * @param {String} caseStudyLength - Total number of case studies records
 * @param {String} capabilitiesLength - Total number of capabilities records
 * @param {String} contactLength - Total number of contacts available
 * @param {Number} selectedTab - Selected tab
 * @example
 * CustomTab({tabTitle, tabContent})
 */
const CustomTab = props => {
  const {
    tabTitle,
    tabContent,
    selectedTab,
    caseStudyLength,
    capabilitiesLength,
    contactLength,
    partnership
  } = props;
  const [activeTab, setActiveTab] = useState(selectedTab);

  const onClickItem = tab => {
    setActiveTab(tab);
  };

  return (
    <TabsWrapper>
      <TabsList>
        {tabTitle.map((tabHeading, index) => (
          <TabListItem
            key={tabHeading}
            title={tabHeading}
            capabilitiesLength={capabilitiesLength}
            caseStudyLength={caseStudyLength}
            partnership={partnership?.length}>
            <Tab
              data-test="tab-click"
              activeTab={activeTab === index}
              onClick={() => onClickItem(index)}
              length={tabTitle.length}>
              <DictionaryContext.Consumer>
                {({ dictionary }) => {
                  return tabHeading ===
                    (dictionary?.caseStudies || Strings.caseStudies)
                    ? caseStudyLength
                      ? `${tabHeading} (${caseStudyLength || "0"})`
                      : null
                    : tabHeading === (dictionary?.assets || Strings.assets)
                    ? capabilitiesLength
                      ? `${tabHeading} (${capabilitiesLength || "0"})`
                      : null
                    : tabHeading === (dictionary?.contact || Strings.contact)
                    ? contactLength
                      ? tabHeading
                      : null
                    : tabHeading ===
                      (dictionary?.partnershipOpportunities ||
                        Strings.partnershipOpportunities)
                    ? partnership?.length
                      ? tabHeading
                      : null
                    : tabHeading;
                }}
              </DictionaryContext.Consumer>
            </Tab>
          </TabListItem>
        ))}
      </TabsList>
      <LineSeparator />
      <TabContentWrapper>
        {tabContent.map(
          (tabItem, index) =>
            activeTab === index && (
              <TabContent key={tabItem}>{tabItem}</TabContent>
            )
        )}
      </TabContentWrapper>
    </TabsWrapper>
  );
};

CustomTab.propTypes = {
  tabTitle: PropTypes.instanceOf(Array),
  tabContent: PropTypes.instanceOf(Array),
  selectedTab: PropTypes.number
};

export default CustomTab;
