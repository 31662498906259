import styled from "styled-components";
import { Button } from "../../../styles/commonStyles";

export const CapabilitiesFilterModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100%;
`;

export const ListsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 96px);
  height: calc(100% - 62px);
  justify-content: flex-start;
  margin: 0 0 10px 96px;
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin: 32px 18px 10px 17px;
    width: calc(100% - 35px);
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 20px 18px 10px 17px;
    width: calc(100% - 35px);
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 2px 18px 10px 17px;
    width: calc(100% - 35px);
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  height: 100%;
  & > ol {
    height: calc(100% - 34px);
  }
  :last-child {
    margin-left: 58px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 50%;
    min-width: 50%;
    :last-child {
      width: calc(50% - 24px);
      min-width: calc(50% - 24px);
      margin-left: 24px;
    }
  }
`;

export const List = styled.ol`
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge add Firefox  */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
`;

export const ListItem = styled.li`
  text-align: left;
  width: 100%;
`;

export const ListItemButton = styled(Button)`
  border: none;
  text-align: left;
  padding: 13px 0;
  width: 100%;
  min-width: min-content;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.content};
  color: ${props =>
    props.select
      ? props.theme.fontColor.activeBlack
      : props.theme.fontColor.inactiveBlack};
  &:hover {
    border: none;
    color: ${props => props.theme.fontColor.activeBlack};
  }
  & > input[type="checkbox"] {
    width: 19px;
    height: 17px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.secondary};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h4`
  margin: 0 0 16px 0;
  padding: 0;
  text-align: left;
  color: ${props => props.theme.fontColor.inactiveBlack};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.tertiary};
  line-height: ${props => props.theme.lineHeight.normal};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-width: max-content;
  }
  @media ${props => props.theme.breakPoints.smallMobileDevice} {
    min-width: auto;
    height: 32px;
  }
`;

export const CategoryTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 29px);
  margin-left: 16px;
  color: ${props =>
    props.select
      ? props.theme.fontColor.activeBlack
      : props.theme.fontColor.inactiveBlack};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: calc(100% - 20px);
    line-height: ${props => props.theme.lineHeight.content};
  }
`;
