import styled from "styled-components";

export const FilterBarWrapper = styled.div`
  width: 100%
  display: block;
  margin-left: auto;
  text-align: right;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    text-align: left;
    margin: 24px 0 0 0;
  }
`;

export const FilterWrapper = styled.div`
  margin: 5px;
  padding: 10px 13px 10px 15px;
  border-radius: 20px;
  background-color: ${props => props.theme.backgroundColor.blue};
  display: inline-flex;
`;

export const FilterTitle = styled.p`
  font-size: ${props => props.theme.fontSize.secondary};
  line-height: ${props => props.theme.lineHeight.normal};
  color: ${props => props.theme.fontColor.primary};
  margin-bottom: 0px;
`;

export const RemoveBtn = styled.button`
  background-color: transparent;
  align-items: center;
  margin: 2px 0 0 2px;
  display: flex;
  border: none;
`;
