import React from "react";
import PropTypes from "prop-types";

import { Carousel } from "./style";

var carouselSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  afterChange: () => {
    var slider = document.querySelectorAll(".slick-slide button");
    slider.forEach(slide => {
      slide.setAttribute("tabIndex", "-1");
    });
    document
      .querySelector(".slick-active button")
      .setAttribute("tabIndex", "0");
  }
};

/**
 * SliderComponent used to render the slider
 * @param {node} children children content to append to the carousel component
 * @returns SliderComponent()
 */
const SliderComponent = ({ children, sliderRef }) => {
  return (
    <Carousel {...carouselSettings} ref={sliderRef}>
      {children}
    </Carousel>
  );
};

SliderComponent.propTypes = {
  children: PropTypes.array,
  sliderRef: PropTypes.object
};

export default SliderComponent;
