import styled from "styled-components";
import {
  CardSubDescription,
  CardWrapper,
  CardSubTitle
} from "../../../styles/commonStyles";

export const IconTextCardWrapper = styled(CardWrapper)`
  padding: 51px 47px 49px;
  width: 374px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 0 18px 0 22px;
    width: 100%;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 0 18px 0 22px;
    width: 374px;
  }
`;

export const Title = styled(CardSubDescription)`
  line-height: ${props => props.theme.lineHeight.default};
  margin: 7px 0 5px 0;
`;
export const Description = styled(CardSubTitle)`
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.default};
  margin-bottom: 0px;
`;
