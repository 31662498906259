import styled from "styled-components";

export const AssetsContentWrapper = styled.div`
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  min-height: 534px;
`;
