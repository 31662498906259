import React, { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import Plyr from "plyr";

import Strings from "../../../resources/strings";
import Images from "../../../resources/images";
import CardGrid from "../../CommonComponents/CardGrid/CardGrid";
import CaseStudyCard from "../../Cards/CaseStudyCard/CaseStudyCard";
import { FeaturedCaseStudy } from "../../../styles/commonStyles";
import Image from "../../CommonComponents/ImageCard/ImageCard";
import { StateContext } from "../../../providers/StateContext";
import { CloseButton } from "../../../styles/commonStyles";
import {
  fetchNameById,
  decodeHTMLMarkup,
  convertToUpperCase
} from "../../../utils/commonUtils";
import RichText from "../../CommonComponents/RichText/RichText";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  CaseStudyLayout,
  CaseStudyTitle,
  CaseStudyDescription,
  ImageMetaDataWrapper,
  ImageMetaSource,
  ImageMetaDuration,
  CaseStudyCardContainer
} from "./style";

/**
 * CaseStudyDetails will be the details page for case studies
 * @param {Function} closeModal
 * @param {Function} handleModal
 * @param {Object} selectedCaseStudy
 * @param {String} type
 * @returns CaseStudyDetails
 */

const CaseStudyDetails = ({
  closeModal,
  handleModal,
  selectedCaseStudy,
  type
}) => {
  const state = useContext(StateContext);
  const { providers, caseStudy } = state;
  const descData = decodeHTMLMarkup(selectedCaseStudy?.Description);

  useEffect(() => {
    Plyr.setup(".plyr-video");
  });

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            <CaseStudyLayout>
              <FeaturedCaseStudy background="true">
                {selectedCaseStudy?.Is_x0020_featured_x0020_content
                  ? convertToUpperCase(
                      dictionary?.featuredCaseStudy || Strings.featuredCaseStudy
                    )
                  : ""}
              </FeaturedCaseStudy>
              <CloseButton
                className="btn btn-transparent p-0"
                onClick={closeModal}>
                <Image
                  img={Images.CloseBlack}
                  alt="close icon"
                  height="32px"
                  width="32px"
                  mobWidth="32px"
                  mobHeight="32px"
                />
              </CloseButton>
              <CaseStudyTitle>{selectedCaseStudy?.Title}</CaseStudyTitle>
              <ImageMetaDataWrapper>
                <ImageMetaSource>
                  {`${dictionary?.by || Strings.by} ${
                    fetchNameById(
                      providers,
                      selectedCaseStudy?.ProvidersLookupId,
                      dictionary?.companyId || Strings.companyId
                    )[0]?.Title
                  } ${dictionary?.team || Strings.team}`}
                </ImageMetaSource>
                <ImageMetaDuration>
                  {`${selectedCaseStudy?.Reading_x0020_Time} ${
                    dictionary?.minRead || Strings.minRead
                  }`}
                </ImageMetaDuration>
              </ImageMetaDataWrapper>
              <CaseStudyDescription>
                <RichText>{descData}</RichText>
              </CaseStudyDescription>
            </CaseStudyLayout>
            <CaseStudyCardContainer>
              {caseStudy?.caseStudyCategory[selectedCaseStudy?.Subcategory]
                ?.length > 1 ? (
                <CardGrid
                  title={
                    dictionary?.similarCaseStudy || Strings.similarCaseStudy
                  }>
                  {caseStudy?.caseStudyCategory[
                    selectedCaseStudy?.Subcategory
                  ].map((caseStudy, index) => {
                    return caseStudy?.id !== selectedCaseStudy?.id ? (
                      <CaseStudyCard
                        key={index}
                        caseStudy={caseStudy}
                        handleModal={
                          type === (dictionary?.explore || Strings.explore)
                            ? () =>
                                handleModal(
                                  dictionary?.caseStudies ||
                                    Strings.caseStudies,
                                  caseStudy
                                )
                            : () => handleModal(caseStudy)
                        }></CaseStudyCard>
                    ) : (
                      ""
                    );
                  })}
                </CardGrid>
              ) : (
                ""
              )}
            </CaseStudyCardContainer>
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

CaseStudyDetails.propTypes = {
  closeModal: PropTypes.func,
  selectedCaseStudy: PropTypes.object,
  type: PropTypes.string,
  handleModal: PropTypes.func
};

export default CaseStudyDetails;
