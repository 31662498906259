import React from "react";
import PropTypes from "prop-types";

import { ModalWrapper, CustomModal } from "./style";

/**
 * Modal Popup component is a popup modal which will introduce user to website and sets success and failure messages for contact form
 * @param {bool} showModal - defines state for modal open or not
 * @param {func} closeModal - close the open modal
 * @param {any} modalRef - reference for the modal element
 * @param {node} children
 * @param {number} currentChildIndex - defines which child visible in modal
 * @param {number} contentChildIndex - defines child's child index
 * @param {bool} isQuickTour - checks whether popup modal related to quick tour
 * @returns
 */
const QuickTourModal = props => {
  const {
    showModal,
    closeModal,
    modalRef,
    children,
    currentChildIndex,
    contentChildIndex,
    isQuickTour
  } = props;
  return (
    <ModalWrapper ref={modalRef}>
      <CustomModal
        show={showModal}
        onHide={closeModal}
        id="quickTour"
        scrollable={true}>
        <CustomModal.Body>
            {isQuickTour? (currentChildIndex === 1
            ? children[currentChildIndex].props.children[contentChildIndex]
            : children[currentChildIndex]) : children}
        </CustomModal.Body>
      </CustomModal>
    </ModalWrapper>
  );
};

QuickTourModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  modalRef: PropTypes.any,
  children: PropTypes.node,
  currentChildIndex: PropTypes.number,
  contentChildIndex: PropTypes.number,
  isQuickTour: PropTypes.bool
};


export default QuickTourModal;
