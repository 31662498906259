import styled from "styled-components";

import { CaseStudyCardWrapper } from "../../Cards/CaseStudyCard/style";
import {
  GridContainerTitle,
  GridLayoutContainer
} from "../../CommonComponents/CardGrid/style";

export const CaseStudyLayout = styled.div`
  width: 100%;
  max-width: 801px;
  margin: 0 auto;
  position: relative;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 0 auto;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 0 auto;
  }
`;

export const CaseStudyTitle = styled.h1`
  font-size: ${props => props.theme.fontSize.mdheading};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.heading};
  color: ${props => props.theme.fontColor.secondary};
  margin-top: 36px;
`;

export const CaseStudyDescription = styled.div`
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: ${props => props.theme.lineHeight.description};
  color: ${props => props.theme.fontColor.manatee};
  margin: 24px 0 0;

  & span {
    font-size: ${props => props.theme.fontSize.primary}!important;
    font-weight: ${props => props.theme.fontWeight.medium};
    line-height: ${props => props.theme.lineHeight.description};
    color: ${props => props.theme.fontColor.activeBlack};
  }

  & img,
  & .plyr--video {
    margin: 30px 0 0;
    width: 100%;
  }
`;

export const ImageMetaDataWrapper = styled.div`
  display: flex;
  margin: 34px 0 14px;
  font-size: ${props => props.theme.fontSize.tertiary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.normal};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 46px 0 8px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 46px 0 8px;
  }
`;

export const ImageMetaSource = styled.p`
  color: ${props => props.theme.fontColor.secondary};
  &:last-child {
    margin-left: 4px;
  }
`;

export const ImageMetaDuration = styled.p`
  color: ${props => props.theme.fontColor.manatee};
  margin: 0 0 0 12px;
`;

export const CaseStudyCardContainer = styled.div`
  max-width: 1000px;
  margin: 80px auto 0;
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    margin: 40px auto 0;
  }
  ${CaseStudyCardWrapper} {
    min-width: 305px;
    margin-right: 20px;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      min-width: 95%;
      margin-right: 10px;
      :last-child {
        margin-right: 0px;
      }
    }
  }
  ${GridContainerTitle} {
    margin-bottom: 12px;
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.fontColor.manatee};
    line-height: ${props => props.theme.lineHeight.description};
  }
  & ${GridLayoutContainer} {
    display: flex;
    flex-wrap: nowrap !important;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      & ${GridLayoutContainer} {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
      }
    }
    @media ${props => props.theme.breakPoints.tabDevice} {
      & ${GridLayoutContainer} {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }
`;
