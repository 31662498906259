import React, { Component } from "react";

import { Router } from "@reach/router";

import { authApiCall } from "../services/api";
import { AuthLoaderWrapper, LoaderWrap } from "../components/Loader/style";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "../components/PrivateRoute";
import { Explore } from "../components/Pages/Explore/Explore";
import { Providers } from "../components/Pages/Providers/Providers";
import { Assets } from "../components/Pages/Capabilities/Capabilities";
import { ProviderDetails } from "../components/Pages/ProviderDetails/ProviderDetails";
import { isBrowser } from "../utils/auth/authConfig";

/**
 * Auth component will handle the route with authentication post login
 * @example Auth()
 */
class Auth extends Component {
  state = {
    token: isBrowser ? window.sessionStorage.getItem("apiOAuthToken") : ""
  };

  componentDidMount() {
    if (!this.state.token) {
      authApiCall().then(res => {
        if (res) {
          this.setState({ token: res });
        }
      });
    }
  }
  render() {
    if (!this.state.token)
      return (
        <AuthLoaderWrapper>
          <LoaderWrap>
            <Loader animation="animateToCenter" />
          </LoaderWrap>
        </AuthLoaderWrapper>
      );
    return (
      <Router>
        <PrivateRoute path="/auth/explore" component={Explore}></PrivateRoute>
        <PrivateRoute
          path="/auth/providers"
          component={Providers}></PrivateRoute>
        <PrivateRoute
          path="/auth/capabilities"
          component={Assets}></PrivateRoute>
        <PrivateRoute
          path="/auth/providers/details"
          component={ProviderDetails}></PrivateRoute>
      </Router>
    );
  }
}

export default Auth;
