import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext
} from "react";

import { Helmet } from "react-helmet";
import { useQueryParam } from "use-query-params";

import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import ProviderDetailsSummary from "../../ProviderDetailsSummary/ProviderDetailsSummary";
import CustomTab from "../../CommonComponents/Tab/Tab";
import { tabTitle } from "../../../utils/titleConfig";
import TabDetailsMarkup from "../../TabContent/TabDetailsMarkup/TabDetailsMarkup";
import AssetsContent from "../../TabContent/Capabilities/Capabilities";
import CaseStudyContent from "../../TabContent/CaseStudies/CaseStudy";
import ContactContent from "../../TabContent/Contact/Contact";
import ContactForm from "../../ContactForm/ContactForm";
import { DetailsPageContainer, ProviderDetailsWrapper } from "./style";
import { StateContext } from "../../../providers/StateContext";
import {
  fetchNameById,
  getRefactoredImageUrl,
  getUserId
} from "../../../utils/commonUtils";
import Strings from "../../../resources/strings";
import { postApiCall } from "../../../services/api";
import { DictionaryContext } from "../../../providers/DictionaryContext";

/**
 * Used to get the Provider Details layout
 * @returns ProviderDetails()
 */
export const ProviderDetails = () => {
  const state = useContext(StateContext);
  const { providers, contacts } = state;
  const capabilitiesMaster = state.capabilities;
  const capabilities = state.capabilitiesCategory;
  const caseStudy = state.caseStudy.caseStudyProviders;
  const capabilitiesSubCategoryLookupId =
    state.lookups.capabilitiesSubCategoryLookupId;
  const capabilitiesList = state.lookups.capabilitiesCategory;

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [businessTableData, setBusinessTableData] = useState(null);
  const providerId = useQueryParam("id")[0];
  const type = useQueryParam("type")[0];
  const contactRef = useRef();
  const tabContent = [
    <TabDetailsMarkup
      content={selectedProvider && selectedProvider?.Overview}
    />,
    <TabDetailsMarkup
      content={selectedProvider && selectedProvider?.Value_x0020_Proposition}
    />,
    <AssetsContent
      capabilities={capabilities[providerId]}
      capabilitiesList={capabilitiesList}
      providers={providers}
      capabilitiesSubCategoryLookupIds={Object.values(
        capabilitiesSubCategoryLookupId
      )}
    />,
    <TabDetailsMarkup
      content={selectedProvider && selectedProvider?.PartnerShipOppertunities}
    />,
    <CaseStudyContent caseStudies={caseStudy[providerId]} />,
    <ContactContent
      businessTable={businessTableData && businessTableData}
      selectedProvider={selectedProvider}
    />
  ];

  useEffect(() => {
    setSelectedProvider(
      providers.filter(provider => provider?.id === providerId)[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedProvider) {
      postApiCall.postPopularCards({
        type: Strings.providers,
        createdTime: selectedProvider?.CreatedTime,
        userId: getUserId(),
        clickedTime: Date.now(),
        listId: selectedProvider?.listId,
        itemId: selectedProvider?.id
      });
    }
    let businessTable = [];
    selectedProvider?.Contact_x0020_Details?.forEach(contact => {
      contacts[contact?.LookupId]["Is_x0020_Relationship_x0020_Mana"] &&
        businessTable.push(contacts[contact?.LookupId]);
    });
    setBusinessTableData(businessTable);
  }, [selectedProvider, contacts]);

  /* handleScroll() is used for scrolling page down to the contact form when user clicks */
  const handleScroll = () => {
    window.scroll(0, contactRef.current.offsetTop);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ProviderDetails | SBG-FRBB</title>
      </Helmet>
      <DictionaryContext.Consumer>
        {({ dictionary }) => {
          return (
            <ProviderDetailsWrapper>
              <ImageCard
                img={getRefactoredImageUrl(dictionary?.ProviderDetailsBanner)}
                height="442px"
                alt="details-card"></ImageCard>
              <DetailsPageContainer>
                {selectedProvider && (
                  <ProviderDetailsSummary
                    handleScroll={handleScroll}
                    provider={selectedProvider}
                    contacts={contacts}
                    data-test="handle-scroll"
                  />
                )}
                <CustomTab
                  capabilitiesLength={
                    fetchNameById(
                      capabilitiesMaster,
                      providerId,
                      "ProvidersLookupId"
                    )?.length
                  }
                  caseStudyLength={caseStudy[providerId]?.length}
                  contactLength={businessTableData?.length}
                  tabContent={tabContent}
                  tabTitle={tabTitle}
                  selectedTab={type ? 2 : 0}
                  partnership={selectedProvider?.PartnerShipOppertunities}
                />
                <ContactForm
                  formRef={contactRef}
                  selectedProvider={selectedProvider}
                  businessTable={businessTableData && businessTableData}
                />
              </DetailsPageContainer>
            </ProviderDetailsWrapper>
          );
        }}
      </DictionaryContext.Consumer>
    </Fragment>
  );
};
