import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import Strings from "../../../resources/strings";
import LineSeparator from "../../CommonComponents/LineSeparator/LineSeparator";
import Accordion from "../../Accordion/Accordion";
import { convertToUpperCase } from "../../../utils/commonUtils";
import { StateContext } from "../../../providers/StateContext";
import { Button, ButtonsWrapper, CheckBox } from "../../../styles/commonStyles";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  Wrapper,
  FilterContentWrapper,
  FilterWrapper,
  ListWrapper,
  List,
  ListItem,
  ListItemButton,
  TitleWrapper,
  Title,
  LocationWrapper,
  SelectedWrapper,
  CategoryName
} from "./style";

/**
 * ProviderFilterModal component will use for Filter in provider or capabilities page
 * @param {Object} selectedData - defines which filter item is selected
 * @param {func} setSelectedData - it will handle selectedData state
 * @param {string} selectedCategory - defines which filter category is selected
 * @param {func} setSelectedCategory - it will handle selectedCategory state
 * @param {func} updateFilterCallback - onClick done button it will pass selected data for filter: ;
 * @param {func} closeModal
 * @example
 * ProviderFilterModal()
 */
const ProviderFilterModal = props => {
  const state = useContext(StateContext);
  const { providersIndustry, providersCategory, lookups } = state;
  const [isChecked, setIsChecked] = useState(false);
  const data = {
    Industry: providersIndustry,
    Category: providersCategory,
    Location: {
      Continent: lookups.location.continent,
      Country: lookups.location.country
    }
  };
  const {
    selectedData,
    setSelectedData,
    selectedCategory,
    setSelectedCategory,
    updateFilterCallback,
    closeModal
  } = props;

  const clearSelectedData = () => {
    setSelectedData({
      Category: [],
      Industry: [],
      Location: []
    });
    setSelectedCategory(null);
  };

  const handleCheckedCategory = keyName => {
    const selectedObject = { ...selectedData };
    setIsChecked(false);
    setSelectedCategory(null);
    switch (keyName) {
      case Strings.categoryTitle:
        return setSelectedData({
          Category: [],
          Industry: [...selectedObject.Industry],
          Location: [...selectedObject.Location]
        });
      case Strings.industry:
        return setSelectedData({
          Category: [...selectedObject.Category],
          Industry: [],
          Location: [...selectedObject.Location]
        });
      default:
        return setSelectedData({
          Category: [...selectedObject.Category],
          Industry: [...selectedObject.Industry],
          Location: []
        });
    }
  };

  const handleSelect = (item, keyName) => {
    const category = keyName;
    const currSelectedData = item;
    const selectedObject = { ...selectedData };
    if (!selectedObject[category].includes(item)) {
      switch (selectedCategory) {
        case Strings.categoryTitle:
          return setSelectedData({
            Category: [...selectedObject.Category, currSelectedData],
            Industry: [...selectedObject.Industry],
            Location: [...selectedObject.Location]
          });
        case Strings.industry:
          return setSelectedData({
            Category: [...selectedObject.Category],
            Industry: [...selectedObject.Industry, currSelectedData],
            Location: [...selectedObject.Location]
          });
        default:
          return setSelectedData({
            Category: [...selectedObject.Category],
            Industry: [...selectedObject.Industry],
            Location: [...selectedObject.Location, currSelectedData]
          });
      }
    } else {
      let selectedArr = selectedObject[category].filter(
        val => val !== currSelectedData
      );
      switch (category) {
        case Strings.categoryTitle:
          return setSelectedData({
            Category: [...selectedArr],
            Industry: [...selectedObject.Industry],
            Location: [...selectedObject.Location]
          });
        case Strings.industry:
          return setSelectedData({
            Category: [...selectedObject.Category],
            Industry: [...selectedArr],
            Location: [...selectedObject.Location]
          });
        default:
          return setSelectedData({
            Category: [...selectedObject.Category],
            Industry: [...selectedObject.Industry],
            Location: [...selectedArr]
          });
      }
    }
  };

  const handleFilterApply = () => {
    updateFilterCallback(selectedData);
    closeModal();
  };

  const checkContinent = continentId => {
    let country = data.Location.Country[continentId];
    let locationData = selectedData.Location;
    if (country && locationData) {
      let isSelect = country.filter(country =>
        locationData.includes(country.Title)
      );
      return isSelect.length ? true : false;
    }
    return false;
  };

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <FilterWrapper>
            <FilterContentWrapper>
              <Wrapper>
                <ListWrapper>
                  <Title>
                    {convertToUpperCase(
                      dictionary?.providerCategory ||
                        Strings.providerCategory.trim()
                    )}
                  </Title>
                  <LineSeparator />
                  <List>
                    {Object.keys(data).map(keyName => {
                      return (
                        <ListItem key={keyName}>
                          <ListItemButton
                            onClick={() => {
                              if (keyName === selectedCategory && isChecked) {
                                handleCheckedCategory(keyName.trim());
                              } else {
                                setSelectedCategory(keyName.trim());
                                setIsChecked(true);
                              }
                            }}>
                            <CheckBox checked={keyName === selectedCategory} />
                            <CategoryName select={keyName === selectedCategory}>
                              {keyName.trim()}
                            </CategoryName>
                          </ListItemButton>
                          <LineSeparator />
                        </ListItem>
                      );
                    })}
                  </List>
                </ListWrapper>
                <SelectedWrapper>
                  {selectedCategory ? (
                    <TitleWrapper>
                      <Title>
                        {convertToUpperCase(
                          dictionary?.subCategory?.trim() ||
                            Strings.subCategory.trim()
                        )}
                      </Title>
                      <LineSeparator />
                    </TitleWrapper>
                  ) : null}

                  {Object.keys(data).map(keyName => {
                    return keyName === selectedCategory &&
                      keyName !== (dictionary?.location || Strings.location) ? (
                      <List key={keyName}>
                        {data[keyName] &&
                          data[keyName].map(item => (
                            <ListItem key={item.Title}>
                              <ListItemButton
                                onClick={() =>
                                  handleSelect(item.Title.trim(), keyName)
                                }>
                                <CheckBox
                                  checked={selectedData[keyName].includes(
                                    item.Title.trim()
                                  )}
                                />
                                <CategoryName
                                  select={selectedData[keyName].includes(
                                    item.Title.trim()
                                  )}>
                                  {item.Title.trim()}
                                </CategoryName>
                              </ListItemButton>
                              <LineSeparator />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      keyName === selectedCategory && (
                        <LocationWrapper key={keyName}>
                          <List>
                            {data[keyName][
                              dictionary?.continent || Strings.continent
                            ].map(continent => {
                              const continentId = continent.id;
                              return (
                                <Accordion
                                  key={continentId}
                                  title={continent.Title}
                                  isSelected={checkContinent(continentId)}
                                  description=""
                                  filter="true">
                                  <List>
                                    {Object.keys(
                                      data[keyName][
                                        dictionary?.country || Strings.country
                                      ]
                                    ).map(id => {
                                      return (
                                        continentId === id &&
                                        data[keyName][
                                          dictionary?.country || Strings.country
                                        ][id].map(country => {
                                          return (
                                            <ListItem key={country.Title}>
                                              <ListItemButton
                                                onClick={() =>
                                                  handleSelect(
                                                    country.Title.trim(),
                                                    dictionary?.location ||
                                                      Strings.location
                                                  )
                                                }
                                                country={true}>
                                                <CheckBox
                                                  checked={selectedData.Location.includes(
                                                    country.Title.trim()
                                                  )}
                                                />
                                                <CategoryName
                                                  select={selectedData.Location.includes(
                                                    country.Title.trim()
                                                  )}>
                                                  {country.Title.trim()}
                                                </CategoryName>
                                              </ListItemButton>
                                              <LineSeparator />
                                            </ListItem>
                                          );
                                        })
                                      );
                                    })}
                                  </List>
                                </Accordion>
                              );
                            })}
                          </List>
                        </LocationWrapper>
                      )
                    );
                  })}
                </SelectedWrapper>
              </Wrapper>
            </FilterContentWrapper>
            <ButtonsWrapper filter="true">
              <Button onClick={clearSelectedData}>
                {dictionary?.clear || Strings.clear}
              </Button>
              <Button primary onClick={handleFilterApply}>
                {dictionary?.done || Strings.done}
              </Button>
            </ButtonsWrapper>
          </FilterWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

ProviderFilterModal.propTypes = {
  selectedData: PropTypes.instanceOf(Object),
  setSelectedData: PropTypes.func,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  clearSelectedData: PropTypes.func,
  applyFilter: PropTypes.func,
  closeModal: PropTypes.func
};

export default ProviderFilterModal;
