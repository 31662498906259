import React from "react";
import PropTypes from "prop-types";

import Strings from "../../../resources/strings";
import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  Wrapper,
  QuickTourStartCardWrapper,
  ButtonsWrapper,
  Description,
  StartCardTitle,
  StartCardButton
} from "./style";
import { getRefactoredImageUrl } from "../../../utils/commonUtils";

/**
 * QuickTourStartCard component will take you through every page details
 * @param {String} title
 * @param {String} description
 * @param {Func} closeModal - defines tour finished or not
 * @param {Func} handleStartTour - onClick start tour button it will go to next stage
 * @param {Object} quickTourStartCard - data to show in start card
 * @example
 * QuickTourStartCard({title, description})
 */
const QuickTourStartCard = props => {
  const { closeModal, handleStartTour, quickTourStartCard } = props;
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <QuickTourStartCardWrapper>
            <Wrapper>
              <ImageCard
                img={getRefactoredImageUrl(quickTourStartCard?.Image?.Url)}
                width="206px"
                height="205px"
                mobHeight="206px"
                mobWidth="205px"
              />
            </Wrapper>
            <Wrapper>
              <StartCardTitle>{quickTourStartCard?.Title}</StartCardTitle>
              <Description>{quickTourStartCard?.Description}</Description>
              <ButtonsWrapper>
                <StartCardButton onClick={closeModal}>
                  {dictionary?.skip || Strings.skip}
                </StartCardButton>
                <StartCardButton primary onClick={handleStartTour}>
                  {dictionary?.startTour || Strings.startTour}
                </StartCardButton>
              </ButtonsWrapper>
            </Wrapper>
          </QuickTourStartCardWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

QuickTourStartCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  closeModal: PropTypes.func,
  handleStartTour: PropTypes.func,
  quickTourStartCard: PropTypes.object
};

export default QuickTourStartCard;
