import React, { useRef, Fragment } from "react";
import PropTypes from "prop-types";

import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import Images from "../../resources/images";
import {
  FilterBarWrapper,
  FilterWrapper,
  FilterTitle,
  RemoveBtn
} from "./style";

/**
 * CapabilitiesFilterBar component is used to render the filter wrappers when the filter is applied
 * @param {object} capabilitiesfilterObject
 * @param {function} setCapabilitiesFilterObject
 * @param {string} filterImage
 * @param {string} altText
 * @param {string} imgHeight 
 * @param {string} imgWidth    
 * @param {string} imgMobHeight 
 * @param {string} imgMobWidth 
 * @param {function} updateFilterCallback 
 * @example
 * CapabilitiesFilterBar({
    capabilitiesfilterObject,
    filterImage,
    altText,
    imgHeight,
    imgWidth,
    imgMobHeight,
    imgMobWidth,
    updateFilterCallback}) 
 */
const CapabilitiesFilterBar = props => {
  const {
    capabilitiesFilterObject,
    filterImage,
    altText,
    imgHeight,
    imgWidth,
    imgMobHeight,
    imgMobWidth,
    updateFilterCallback,
    setCapabilitiesFilterObject
  } = props;

  const filterNode = useRef(null);

  const removeFilterHandler = item => {
    const selectedItem = item;
    const removedData =
      capabilitiesFilterObject.subCategory.length > 0 &&
      capabilitiesFilterObject.subCategory.filter(
        data => data.subCategory !== selectedItem
      );
    capabilitiesFilterObject.subCategory = [...removedData];
    setCapabilitiesFilterObject(capabilitiesFilterObject);
    updateFilterCallback(capabilitiesFilterObject);
  };

  return (
    <FilterBarWrapper ref={filterNode}>
      {capabilitiesFilterObject &&
        Object.keys(capabilitiesFilterObject).map(type => {
          return (
            <Fragment key={type}>
              {capabilitiesFilterObject[type].length > 0 &&
                capabilitiesFilterObject[type].map((item, index) => {
                  return (
                    <FilterWrapper key={index}>
                      <FilterTitle>{item.subCategory.trim()}</FilterTitle>
                      <RemoveBtn
                        data-test="remove-filter-item"
                        onClick={() => {
                          removeFilterHandler(item.subCategory.trim());
                        }}>
                        <ImageCard
                          img={filterImage}
                          alt={altText}
                          height={imgHeight}
                          width={imgWidth}
                          mobHeight={imgMobHeight}
                          mobWidth={imgMobWidth}
                        />
                      </RemoveBtn>
                    </FilterWrapper>
                  );
                })}
            </Fragment>
          );
        })}
    </FilterBarWrapper>
  );
};

CapabilitiesFilterBar.propTypes = {
  capabilitiesFilterObject: PropTypes.instanceOf(Object),
  setCapabilitiesFilterObject: PropTypes.func,
  updateFilterCallback: PropTypes.func,
  filterImage: PropTypes.string,
  altText: PropTypes.string,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  imgMobHeight: PropTypes.string,
  imgMobWidth: PropTypes.string
};

CapabilitiesFilterBar.defaultProps = {
  filterImage: Images.CloseWhite,
  altText: "filter image",
  imgHeight: "14px",
  imgWidth: "14px",
  imgMobHeight: "14px",
  imgMobWidth: "14px"
};

export default CapabilitiesFilterBar;
