import React from "react";
import PropTypes from "prop-types";

import { ContactTableData, MainText, SubText, ContactTableRow } from "./style";
import { TableBody, TableWrapper } from "../../../styles/commonStyles";

/**
 * ContactTable component will use to show different details for contact
 * @param {Array} tableData
 * @example
 * ContactTable({tableHeading, tableData})
 */
const ContactTable = props => {
  const { tableData } = props;
  return (
    <TableWrapper>
      <TableBody>
        {tableData.map((tableData, index) => (
          <ContactTableRow key={index}>
            <ContactTableData>
              <MainText>{tableData?.Name}</MainText>
              <SubText>{tableData?.Title}</SubText>
            </ContactTableData>
            <ContactTableData>{tableData?.emailID}</ContactTableData>
          </ContactTableRow>
        ))}
      </TableBody>
    </TableWrapper>
  );
};

ContactTable.propTypes = {
  tableData: PropTypes.instanceOf(Array)
};

export default ContactTable;
