import styled from "styled-components";

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 21px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 21px;
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin-top: 21px;
  }
`;

export const DropdownWrapper = styled.button`
  margin-top: 5px;
  padding: 0 12px 0 20px;
  width: max-content;
  min-height: 36px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.normal};
  color: ${props => props.theme.fontColor.darkGrey};
  justify-content: space-between;
  & > img {
    margin-left: 6px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    min-height: 30px;
    padding: 0 15px;
    & > img {
      margin-left: 0;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-height: 30px;
    padding: 0 15px;
    & > img {
      margin-left: 0;
    }
  }
`;

export const TitleDropdownDesktopWrapper = styled.div`
  width: calc(100% - 300px);
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: none;
  }
`;
export const TitleDropdownMobileWrapper = styled.div`
  display: none;
  width: 100%;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: block;
  }
`;
