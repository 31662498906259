import React from "react";
import PropTypes from "prop-types";

import { decodeHTMLMarkup } from "../../../utils/commonUtils";
import RichText from "../../CommonComponents/RichText/RichText";
import { TabContentWrapper } from "./style";

/**
 * TabDetailsMarkup component will render the HTML RichText for providers details page
 * @param {String} content
 * @example
 * TabDetailsMarkup()
 */
const TabDetailsMarkup = ({ content }) => {
  const descData = decodeHTMLMarkup(content);

  return (
    <TabContentWrapper>
      <RichText>{descData}</RichText>
    </TabContentWrapper>
  );
};

TabDetailsMarkup.propTypes = {
  content: PropTypes.string
};

export default TabDetailsMarkup;
