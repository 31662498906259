import React, { useState, Fragment, useRef, useEffect } from "react";

import Strings from "../../resources/strings";
import IconTextCard from "../Cards/IconTextCard/IconTextCard";
import ModalPopup from "../ModalPopup/ModalPopup";
import Images from "../../resources/images";
import Loader from "../Loader/Loader";
import { DictionaryContext } from "../../providers/DictionaryContext";
import {
  ContactWrapper,
  ContactTitle,
  ContactInputWrapper,
  ContactMessage,
  ContactSubject,
  ContactButton,
  ButtonWrapper
} from "./style";
import { postApiCall } from "../../services/api";
import { getUserId, getUserName } from "../../utils/commonUtils";

/**
 * Contact Form is for the details that includes Subject and Message
 * @example ContactForm()
 */
const ContactForm = ({ formRef, businessTable, selectedProvider }) => {
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [relationShipManagerEmail, setRelationShipManagerEmail] = useState([]);
  const modalPopupRef = useRef(null);
  const [showModalPopup, setShowModalPopup] = useState(false);

  useEffect(() => {
    let email = [];
    businessTable?.map(data => email.push(data?.emailID));
    setRelationShipManagerEmail(email);
  }, [businessTable]);

  const handleCloseModal = () => {
    modalPopupRef.current.classList.remove("fade");
    setShowModalPopup(false);
  };

  const resetContactForm = () => {
    setSubject("");
    setMessage("");
  };

  const handleLoader = () => {
    setLoader(false);
  };

  const handleModalPopup = () => {
    setShowModalPopup(true);
    setLoader(true);
    postApiCall
      .postContactForm({
        userId: getUserId(),
        userName: getUserName(),
        subject: subject,
        description: message,
        page: selectedProvider?.Title,
        relationalManager: relationShipManagerEmail
      })
      .then(response => {
        setLoader(false);
        if (response?.responseMessage === "Success") {
          setSuccess(true);
          resetContactForm();
        } else {
          setSuccess(false);
        }
      });
  };

  const handleSubjectChange = event => {
    setSubject(event.target.value);
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            <ContactWrapper ref={formRef}>
              <ContactTitle>
                {dictionary?.contactTitle || Strings.contactTitle}
              </ContactTitle>
              <ContactInputWrapper>
                <ContactSubject
                  placeholder={dictionary?.subject || Strings.subject}
                  onChange={handleSubjectChange}
                  value={subject}
                />
                <ContactMessage
                  placeholder={dictionary?.message || Strings.message}
                  onChange={handleMessageChange}
                  value={message}
                />
              </ContactInputWrapper>
              <ButtonWrapper>
                <ContactButton
                  primary
                  disabled={!subject || !message}
                  onClick={handleModalPopup}>
                  {dictionary?.sendMessage || Strings.sendMessage}
                </ContactButton>
              </ButtonWrapper>
            </ContactWrapper>
            <ModalPopup
              showModal={showModalPopup}
              closeModal={handleCloseModal}
              modalRef={modalPopupRef}>
              {loader ? (
                <Loader
                  handleLoader={handleLoader}
                  isContactForm={true}
                  animation="animateToCenter"
                />
              ) : success ? (
                <IconTextCard
                  handleCloseModal={handleCloseModal}
                  title={dictionary?.thankYou || Strings.thankYou}
                  description={
                    dictionary?.successSentMessage || Strings.successSentMessage
                  }
                  image={Images.SuccessImg}
                  width="75px"
                  height="75px"
                  mobHeight="75px"
                  mobWidth="75px"
                />
              ) : (
                <IconTextCard
                  handleCloseModal={handleCloseModal}
                  title={dictionary?.errorMessage || Strings.errorMessage}
                  description={dictionary?.tryAgain || Strings.tryAgain}
                  image={Images.ErrorIcon}
                  width="75px"
                  height="75px"
                  mobHeight="75px"
                  mobWidth="75px"
                />
              )}
            </ModalPopup>
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

export default ContactForm;
