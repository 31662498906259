import styled from "styled-components";

export const ExploreContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 440px;
  position: relative;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    height: 500px;
  }
`;

export const ExploreContent = styled.div`
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.2)
  );
  border-radius: 16px;
  position: absolute;
  top: 0;
  height: 440px;
  left: 0;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    height: 500px;
  }
`;

export const ExploreImageWrapper = styled.div`
  height: inherit;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.2)
  );
  border-radius: 16px;
  & > img {
    width: 100%;
    height: inherit;
    border-radius: 16px;
    object-fit: cover;
  }
`;

export const ExploreBottomWrapper = styled.div`
  max-width: 500px;
`;

export const ExploreContentWrapper = styled.div`
  padding: 32px 0 32px 32px;
  box-sizing: border-box;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${props => props.theme.breakPoints.tabDevice} {
    max-width: 80%;
    padding: 23px 0 28px 21px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    max-width: 95%;
    padding: 23px 0 28px 21px;
  }
`;

export const CaseStudyImgTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CaseStudyImgWrapper = styled.div`
  border-radius: 12px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  background-color: ${props => props.theme.backgroundColor.white};
  & > img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
`;

export const CaseStudyTextWrapper = styled.p`
  font-family: ${props => props.theme.fontFamily.Helvetica};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.medium};
  color: ${props => props.theme.fontColor.white};
  margin: 0 0 0 10px;
`;

export const CaseStudyTitle = styled.h1`
  margin: 16px 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-shadow: ${props => props.theme.shadow.bannerTitle};
  font-size: ${props => props.theme.fontSize.sdheading};
  font-weight: ${props => props.theme.fontWeight.bolder};
  line-height: ${props => props.theme.lineHeight.connect};
  color: ${props => props.theme.fontColor.white};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 16px 0 24px;
  }
`;

export const CaseStudyDescription = styled.p`
  opacity: 0.89;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 50px;
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.medium};
  color: ${props => props.theme.fontColor.white};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: none;
  }

  & span,
  & div {
    font-size: ${props => props.theme.fontSize.medium} !important;
    font-weight: ${props => props.theme.fontWeight.bold} !important;
    line-height: ${props => props.theme.lineHeight.medium} !important;
    color: ${props => props.theme.fontColor.white} !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
