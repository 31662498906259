import styled, { css } from "styled-components";

import Strings from "../../../resources/strings";

export const TabsWrapper = styled.div`
  margin: 10px 0px;
  width: 100%;
  border-radius: 12px;
  background-color: ${props => props.theme.backgroundColor.white};
  padding: 43px 44px 45px;
  box-shadow: ${props => props.theme.shadow.card};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 20px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: 20px;
  }
`;

export const TabsList = styled.ol`
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
  overflow-x: auto;
  display: flex;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding-bottom: 6px;
    margin-bottom: -6px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding-bottom: 6px;
    margin-bottom: -6px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TabListItem = styled.li`
  min-width: fit-content;
  padding: ${props =>
    props?.title === Strings.caseStudies
      ? props?.caseStudyLength
        ? "5px 2px 0px 0px"
        : "0"
      : props?.title === Strings.assets
      ? props?.capabilitiesLength
        ? "5px 2px 0px 0px"
        : "0"
      : props?.title === Strings.partnershipOpportunities
      ? props?.partnership
        ? "5px 2px 0px 0px"
        : "0"
      : "5px 2px 0px 0px"};
  margin: ${props =>
    props?.title === Strings.caseStudies
      ? props?.caseStudyLength
        ? "0px 13px"
        : "0"
      : props?.title === Strings.assets
      ? props?.capabilitiesLength
        ? "0px 13px"
        : "0"
      : props?.title === Strings.partnershipOpportunities
      ? props?.partnership
        ? "0px 13px"
        : "0"
      : "0px 13px"};
  :first-child {
    margin: 0px;
    margin-right: 13px;
  }
  :last-child {
    margin: 0px;
    margin-left: 13px;
  }
`;

export const Tab = styled.button`
  width: auto;
  border: none;
  text-align: left;
  padding-bottom: 10px;
  background-color: ${props => props.theme.backgroundColor.white};
  color: ${props => props.theme.fontColor.inactiveBlack};
  font-size: ${props => props.theme.fontSize.secondary};
  line-height: ${props => props.theme.lineHeight.normal};
  ${props =>
    props.activeTab &&
    css`
      border-bottom: 4px solid ${props => props.theme.fontColor.linkColor};
      color: ${props => props.theme.fontColor.activeBlack};
    `}
  :first-child {
    margin-left: 0px;
  }
  :last-child {
    margin-right: 0px;
  }
`;

export const TabContentWrapper = styled.div`
  overflow-x: auto;
  max-height: 702px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    max-height: 960px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8f90a6;
    border-radius: 10px;
  }
  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
`;

export const TabContent = styled.div`
  padding: 39px 12px 0 0;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 41px 12px 0 0;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: 41px 12px 0 0;
  }
`;
