import styled from "styled-components";
import {
  CardDescription,
  CardWrapper,
  CardTitle,
  Button
} from "../../../styles/commonStyles";

export const QuickTourStartCardWrapper = styled(CardWrapper)`
  width: 808px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-top: 50px;
  box-shadow: ${props => props.theme.boxShadow.card};
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    left: 110px;
  }
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    left: 120px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 90%;
    left: 0;
    margin: 40px 20px 20px 20px;
    padding: 0 20px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    left: 0px;
    margin: 40px 20px 20px 20px;
    padding: 0 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 38px;
  & > img {
    margin-top: 15px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 48px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 48px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 41px;
  & > * {
    min-width: 192px;
  }
  & > :last-child {
    margin-left: 16px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    & > * {
      min-width: 162px;
    }
    & > :last-child {
      margin-left: 10px;
    }
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    & > :last-child {
      margin-left: 10px;
    }
  }
  @media ${props => props.theme.breakPoints.smallMobileDevice} {
    width: 100%;
    flex-direction: column;
    & > :last-child {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

export const Description = styled(CardDescription)`
  line-height: ${props => props.theme.lineHeight.content};
  color: ${props => props.theme.fontColor.activeBlack};
  font-size: ${props => props.theme.fontSize.primary};
  text-align: center;
  display: block;
  margin: 12px 0 44px;
  max-width: 625px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    max-width: 100%;
    margin: 20px 0 48px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 20px 0 48px;
  }
`;

export const StartCardTitle = styled(CardTitle)`
  line-height: ${props => props.theme.lineHeight.description};
  font-size: ${props => props.theme.fontSize.connect};
`;

export const StartCardButton = styled(Button)`
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.default};
`;
