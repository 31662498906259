import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import ImageCard from "../ImageCard/ImageCard";
import Images from "../../../resources/images";
import Strings from "../../../resources/strings";
import PageModal from "../../PageModal/PageModal";
import ProviderFilterModal from "../../FilterModal/ProviderFilterModal/ProviderFilterModal";
import ProviderFilterBar from "../../FilterBar/ProviderFilterBar";
import CapabilitiesFilterModal from "../../FilterModal/CapabilitiesFilterModal/CapabilitiesFilterModal";
import CapabilitiesFilterBar from "../../FilterBar/CapabilitiesFilterBar";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  BannerTitle,
  OnlyMobileView,
  OnlyWebView
} from "../../../styles/commonStyles";
import {
  ContentWrapper,
  DropdownWrapper,
  TitleDropdownDesktopWrapper,
  TitleDropdownMobileWrapper
} from "./style";

/**
 * Component that contains title with dropdown for filter
 * @param {string} title - to show as tile
 * @param {string} dropdownText - to show inside dropdown
 * @param {bool} provider - to decide which filterbar and filtermodal apply
 * @param {object} filterObject - to show as filterObject
 * @param {function} setFilterObject - to set new filterObject
 * @param {function} updateFilterCallback - to use as callback function during filter
 * @param {string} selectedCategory - which category selected
 * @param {func} setSelectedCategory - handle selectedCategory state
 * @param {Object} capabilitiesFilterObject - selected data comes from capabilties to set capabilities filtermodal
 * @param {func} setCapabilitiesFilterObject - handle capabilitiesFilterObject state
 * @param {string} currentCapabilitiesCategory - defines which category selected in capabilitiesFilterModal
 * @param {func} setCurrentCapabilitiesCategory - handle currentCapabilitiesCategory state
 * @example 
 * TitleDropdown ({
  title,
  dropdownText,
  provider,
  filterObject,
  setFilterObject,
  updateFilterCallback,
  selectedCategory,
  setSelectedCategory,
  capabilitiesFilterObject,
  setCapabilitiesFilterObject,
  currentCapabilitiesCategory,
  setCurrentCapabilitiesCategory
})
 */
const TitleDropdown = ({
  title,
  dropdownText,
  provider,
  filterObject,
  setFilterObject,
  updateFilterCallback,
  selectedCategory,
  setSelectedCategory,
  capabilitiesFilterObject,
  setCapabilitiesFilterObject,
  currentCapabilitiesCategory,
  setCurrentCapabilitiesCategory
}) => {
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProviderData, setSelectedProviderData] = useState(null);
  const [selectedCapabilitiesData, setSelectedCapabilitiesData] =
    useState(null);

  useEffect(() => {
    setSelectedProviderData({ ...filterObject });
    setSelectedCapabilitiesData({ ...capabilitiesFilterObject });
  }, [capabilitiesFilterObject, filterObject]);

  const handleModal = () => {
    selectedCategory !== undefined
      ? setSelectedCategory(null)
      : setCurrentCapabilitiesCategory(null);
    setShowModal(true);
  };

  const closeModal = () => {
    modalRef.current.classList.remove("fade");
    setShowModal(false);
  };

  return (
    <Fragment>
      <ContentWrapper>
        <BannerTitle>{title}</BannerTitle>
        <TitleDropdownDesktopWrapper>
          {provider ? (
            <ProviderFilterBar
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              updateFilterCallback={updateFilterCallback}
            />
          ) : (
            <CapabilitiesFilterBar
              capabilitiesFilterObject={capabilitiesFilterObject}
              setCapabilitiesFilterObject={setCapabilitiesFilterObject}
              updateFilterCallback={updateFilterCallback}
            />
          )}
        </TitleDropdownDesktopWrapper>
        {dropdownText ? (
          <Fragment>
            <OnlyWebView>
              <DropdownWrapper
                onClick={handleModal}
                data-test="dropdownwrapper">
                {dropdownText}
                <ImageCard
                  img={Images.DownArrow}
                  width="16px"
                  height="16px"
                  mobWidth="16px"
                  mobHeight="16px"
                  alt="arrow"></ImageCard>
              </DropdownWrapper>
            </OnlyWebView>
            <OnlyMobileView>
              <DropdownWrapper onClick={handleModal}>
                <ImageCard
                  img={Images.FilterMobile}
                  width="16px"
                  height="16px"
                  mobWidth="16px"
                  mobHeight="16px"
                  alt="arrow"></ImageCard>
              </DropdownWrapper>
            </OnlyMobileView>
          </Fragment>
        ) : null}
      </ContentWrapper>
      <TitleDropdownMobileWrapper>
        {provider ? (
          <ProviderFilterBar
            filterObject={filterObject}
            setFilterObject={setFilterObject}
            updateFilterCallback={updateFilterCallback}
          />
        ) : (
          <CapabilitiesFilterBar
            capabilitiesFilterObject={capabilitiesFilterObject}
            setCapabilitiesFilterObject={setCapabilitiesFilterObject}
            updateFilterCallback={updateFilterCallback}
          />
        )}
      </TitleDropdownMobileWrapper>
      {provider ? (
        <DictionaryContext.Consumer>
          {({ dictionary }) => {
            return (
              <PageModal
                handleModal={handleModal}
                showModal={showModal}
                closeModal={() => {
                  filterObject?.Industry?.length
                    ? setSelectedCategory(
                        dictionary?.industry || Strings.industry
                      )
                    : filterObject?.Category?.length
                    ? setSelectedCategory(
                        dictionary?.categoryTitle || Strings.categoryTitle
                      )
                    : filterObject?.Location?.length
                    ? setSelectedCategory(
                        dictionary?.location || Strings.location
                      )
                    : setSelectedCategory(null);
                  setSelectedProviderData({ ...filterObject });
                  closeModal();
                }}
                filter="true"
                modalRef={modalRef}>
                <ProviderFilterModal
                  selectedData={selectedProviderData}
                  setSelectedData={setSelectedProviderData}
                  updateFilterCallback={updateFilterCallback}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  closeModal={closeModal}
                  data-test="page-modal"
                />
              </PageModal>
            );
          }}
        </DictionaryContext.Consumer>
      ) : (
        <DictionaryContext.Consumer>
          {({ dictionary }) => {
            return (
              <PageModal
                handleModal={handleModal}
                showModal={showModal}
                closeModal={() => {
                  let filteredCategory =
                    capabilitiesFilterObject.subCategory.filter(
                      item => item.category === currentCapabilitiesCategory
                    );
                  !filteredCategory.length &&
                    setCurrentCapabilitiesCategory(
                      dictionary?.technology || Strings.technology
                    );
                  setSelectedCapabilitiesData({ ...capabilitiesFilterObject });
                  closeModal();
                }}
                filter="true"
                modalRef={modalRef}
                data-test="page-modal">
                <CapabilitiesFilterModal
                  closeModal={closeModal}
                  updateFilterCallback={updateFilterCallback}
                  capabilitiesFilterObject={capabilitiesFilterObject}
                  selectedData={selectedCapabilitiesData}
                  setSelectedData={setSelectedCapabilitiesData}
                  currentCapabilitiesCategory={currentCapabilitiesCategory}
                  setCurrentCapabilitiesCategory={
                    setCurrentCapabilitiesCategory
                  }
                />
              </PageModal>
            );
          }}
        </DictionaryContext.Consumer>
      )}
    </Fragment>
  );
};

TitleDropdown.propTypes = {
  title: PropTypes.string,
  dropdownText: PropTypes.string,
  provider: PropTypes.bool,
  filterObject: PropTypes.instanceOf(Object),
  setFilterObject: PropTypes.func,
  updateFilterCallback: PropTypes.func,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  capabilitiesFilterObject: PropTypes.instanceOf(Object),
  currentCapabilitiesCategory: PropTypes.string,
  setCurrentCapabilitiesCategory: PropTypes.func
};

export default TitleDropdown;
