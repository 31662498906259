import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import Strings from "../../../resources/strings";
import LineSeparator from "../../CommonComponents/LineSeparator/LineSeparator";
import { StateContext } from "../../../providers/StateContext";
import { convertToUpperCase } from "../../../utils/commonUtils";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { Button, ButtonsWrapper, CheckBox } from "../../../styles/commonStyles";
import {
  CapabilitiesFilterModalWrapper,
  ListsWrapper,
  List,
  ListItem,
  ListItemButton,
  Title,
  TitleWrapper,
  ListWrapper,
  CategoryTitle
} from "./style";

/**
 * CapabilitiesFilterModal Component apply filter assets
 * @param {func} closeModal
 * @param {func} updateFilterCallback
 * @param {Object} capabilitiesFilterObject
 * @param {Object} selectedData
 * @param {func} setSelectedData
 * @param {number} currentCapabilitiesCategory
 * @param {func} setCurrentCapabilitiesCategory
 * @example
 * CapabilitiesFilterModal({closeModal, updateFilterCallback, capabilitiesFilterObject, selectedData, setSelectedData, currentCapabilitiesCategory, setCurrentCapabilitiesCategory})
 */
const CapabilitiesFilterModal = props => {
  const {
    closeModal,
    updateFilterCallback,
    capabilitiesFilterObject,
    selectedData,
    setSelectedData,
    currentCapabilitiesCategory,
    setCurrentCapabilitiesCategory
  } = props;
  const state = useContext(StateContext);
  const [isChecked, setIsChecked] = useState(false);
  const { lookups } = state;
  const data = {
    category: lookups.capabilitiesCategory,
    subCategory: lookups.capabilitiesSubCategory
  };

  const clearSelectedData = () => {
    setCurrentCapabilitiesCategory(null);
    setSelectedData({
      subCategory: []
    });
  };

  const applyFilter = () => {
    JSON.stringify(capabilitiesFilterObject) !== JSON.stringify(selectedData) &&
      updateFilterCallback({ ...selectedData });
    closeModal();
  };

  const handleSelect = (item, category) => {
    const currSelectedSubCateogry = item;
    const currSelectedObject = { category: category, subCategory: item };
    const selectedObject = [...selectedData.subCategory];

    if (!selectedObject) {
      let updatedData = [...selectedObject, currSelectedObject];
      setSelectedData({
        subCategory: [...updatedData]
      });
    } else {
      let check = selectedObject.filter(
        item => item.subCategory === currSelectedSubCateogry
      );
      if (!check.length) {
        setSelectedData({
          subCategory: [...selectedObject, currSelectedObject]
        });
      } else {
        let updatedData = selectedObject.filter(
          val => val.subCategory !== currSelectedSubCateogry
        );
        setSelectedData({
          subCategory: [...updatedData]
        });
      }
    }
  };

  const checkCategory = (item, type = "") => {
    let checkedData = selectedData.subCategory.filter(val =>
      type ? val.category === item : val.subCategory === item
    );
    return checkedData.length ? true : false;
  };

  const handleCheckedCategory = title => {
    let tempArray = selectedData?.subCategory?.filter(
      value => value?.category !== title
    );
    setSelectedData({ subCategory: [...tempArray] });
    setCurrentCapabilitiesCategory(null);
    setIsChecked(false);
  };

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <CapabilitiesFilterModalWrapper>
            <ListsWrapper>
              <ListWrapper>
                <TitleWrapper>
                  <Title>
                    {convertToUpperCase(
                      dictionary?.capabilitiesCategory?.trim() ||
                        Strings.capabilitiesCategory.trim()
                    )}
                  </Title>
                  <LineSeparator />
                </TitleWrapper>
                <List>
                  {data.category.map(item => {
                    return (
                      data?.subCategory[item?.Title]?.length && (
                        <ListItem key={item?.Title?.trim()}>
                          <ListItemButton
                            data-test="capabilities-category"
                            onClick={() => {
                              if (
                                item?.Title?.trim() ===
                                  currentCapabilitiesCategory &&
                                isChecked
                              ) {
                                handleCheckedCategory(item?.Title?.trim());
                              } else {
                                setCurrentCapabilitiesCategory(
                                  item?.Title?.trim()
                                );
                                setIsChecked(true);
                              }
                            }}>
                            <CheckBox
                              checked={
                                item?.Title?.trim() ===
                                currentCapabilitiesCategory
                              }
                            />
                            <CategoryTitle
                              select={
                                item?.Title?.trim() ===
                                currentCapabilitiesCategory
                              }>
                              {item.Title.trim()}
                            </CategoryTitle>
                          </ListItemButton>
                          <LineSeparator />
                        </ListItem>
                      )
                    );
                  })}
                </List>
              </ListWrapper>
              <ListWrapper>
                {currentCapabilitiesCategory ? (
                  <TitleWrapper>
                    <Title>
                      {convertToUpperCase(
                        dictionary?.subCategory?.trim() ||
                          Strings.subCategory.trim()
                      )}
                    </Title>
                    <LineSeparator />
                  </TitleWrapper>
                ) : null}

                <List>
                  {data?.subCategory[currentCapabilitiesCategory]?.map(item => (
                    <ListItem key={item?.trim()}>
                      <ListItemButton
                        data-test="capabilities-subcategory"
                        onClick={() =>
                          handleSelect(
                            item?.trim(),
                            currentCapabilitiesCategory
                          )
                        }>
                        <CheckBox checked={checkCategory(item)} />
                        <CategoryTitle select={checkCategory(item)}>
                          {item?.trim()}
                        </CategoryTitle>
                      </ListItemButton>
                      <LineSeparator />
                    </ListItem>
                  ))}
                </List>
              </ListWrapper>
            </ListsWrapper>
            <ButtonsWrapper filter="true">
              <Button onClick={clearSelectedData}>
                {dictionary?.clear || Strings.clear}
              </Button>
              <Button primary onClick={applyFilter}>
                {dictionary?.done || Strings.done}
              </Button>
            </ButtonsWrapper>
          </CapabilitiesFilterModalWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

CapabilitiesFilterModal.propTypes = {
  closeModal: PropTypes.func,
  updateFilterCallback: PropTypes.func,
  capabilitiesFilterObject: PropTypes.instanceOf(Object),
  selectedData: PropTypes.instanceOf(Object),
  setSelectedData: PropTypes.func,
  currentCapabilitiesCategory: PropTypes.string,
  setCurrentCapabilitiesCategory: PropTypes.func
};

export default CapabilitiesFilterModal;
