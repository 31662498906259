import React from "react";
import PropTypes from "prop-types";

import {
  CardWrapper,
  ImageWrapper,
  Title,
  ContentWrapper,
  Content,
  CapabilityContentButton
} from "./style";
import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import { getCapabilitiesLogo } from "../../../utils/titleConfig";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { getRefactoredImageUrl } from "../../../utils/commonUtils";

/**
 * AssetsContentCard component is a card which will be use in Assets Tab content
 * @param {string} title - provides title for card
 * @param {string} content - provides description for card
 * @example
 * AssetsContentCard({title, content})
 */
const AssetsContentCard = props => {
  const { title, content, handleModal } = props;
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <CapabilityContentButton onClick={handleModal}>
            <CardWrapper>
              <ImageWrapper>
                <ImageCard
                  img={getRefactoredImageUrl(
                    dictionary[getCapabilitiesLogo(title)]
                  )}
                  width="auto"
                  height="auto"></ImageCard>
              </ImageWrapper>
              <ContentWrapper>
                <Title>{title}</Title>
                <Content>{content}</Content>
              </ContentWrapper>
            </CardWrapper>
          </CapabilityContentButton>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

AssetsContentCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default AssetsContentCard;
