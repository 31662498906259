import React, { useRef, Fragment } from "react";
import PropTypes from "prop-types";

import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import Images from "../../resources/images";
import {
  FilterBarWrapper,
  FilterWrapper,
  FilterTitle,
  RemoveBtn
} from "./style";

/**
 * ProviderFilterBar component is used to render the filter wrappers when the filter is applied
 * @param {object} filterObject
 * @param {function} setFilterObject
 * @param {string} filterImage
 * @param {string} altText
 * @param {string} imgHeight 
 * @param {string} imgWidth    
 * @param {string} imgMobHeight 
 * @param {string} imgMobWidth 
 * @param {function} updateFilterCallback 
 * @example
 * ProviderFilterBar({
    filterObject,
    setFilterObject
    filterImage,
    altText,
    imgHeight,
    imgWidth,
    imgMobHeight,
    imgMobWidth,
    updateFilterCallback}) 
 */
const ProviderFilterBar = props => {
  const {
    filterObject,
    setFilterObject,
    filterImage,
    altText,
    imgHeight,
    imgWidth,
    imgMobHeight,
    imgMobWidth,
    updateFilterCallback
  } = props;

  const filterNode = useRef(null);

  const removeFilterHandler = (item, type) => {
    const selectedItem = item;
    const selectedCategory = type;
    const removedData =
      filterObject[selectedCategory].length > 0 &&
      filterObject[selectedCategory].filter(val => val !== selectedItem);
    filterObject[selectedCategory] = [...removedData];
    setFilterObject(filterObject);
    updateFilterCallback(filterObject);
  };

  return (
    <FilterBarWrapper ref={filterNode}>
      {filterObject &&
        Object.keys(filterObject).map(type => {
          return (
            <Fragment key={type}>
              {filterObject[type].length > 0 &&
                filterObject[type].map((item, index) => (
                  <FilterWrapper key={index}>
                    <FilterTitle>{item.trim()}</FilterTitle>
                    <RemoveBtn
                      data-test="remove-filter-item"
                      onClick={() => {
                        removeFilterHandler(item.trim(), type);
                      }}>
                      <ImageCard
                        img={filterImage}
                        alt={altText}
                        height={imgHeight}
                        width={imgWidth}
                        mobHeight={imgMobHeight}
                        mobWidth={imgMobWidth}
                      />
                    </RemoveBtn>
                  </FilterWrapper>
                ))}
            </Fragment>
          );
        })}
    </FilterBarWrapper>
  );
};

ProviderFilterBar.propTypes = {
  filterObject: PropTypes.instanceOf(Object),
  setFilterObject: PropTypes.func,
  updateFilterCallback: PropTypes.func,
  filterImage: PropTypes.string,
  altText: PropTypes.string,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  imgMobHeight: PropTypes.string,
  imgMobWidth: PropTypes.string
};

ProviderFilterBar.defaultProps = {
  filterImage: Images.CloseWhite,
  altText: "filter image",
  imgHeight: "14px",
  imgWidth: "14px",
  imgMobHeight: "14px",
  imgMobWidth: "14px"
};

export default ProviderFilterBar;
