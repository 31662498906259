import styled from "styled-components";

import { Button } from "../../styles/commonStyles";

export const ContactWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 45px 112px 67px 111px;
  margin: 60px 0px;
  background-color: ${props => props.theme.backgroundColor.white};
  box-shadow: ${props => props.theme.shadow.card};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 24px 20px 50px 20px;
    margin: 30px 0px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: 24px 20px 50px 20px;
    margin: 30px 0px;
  }
`;

export const ContactTitle = styled.h2`
  font-size: ${props => props.theme.fontSize.quaternary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.primary};
  color: ${props => props.theme.fontColor.activeBlack};
  text-align: center;
`;

export const ContactInputWrapper = styled.div`
  margin-top: 43px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 40px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 40px;
  }
`;

export const ContactSubject = styled.input`
  width: 100%;
  min-height: 40px;
  margin: 0 0px 30px 0;
  padding: 9px 28px 8px 21px;
  border-radius: 3px;
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: ${props => props.theme.lineHeight.description};
  border: ${props => props.theme.border.inputBorder};
  color: ${props => props.theme.fontColor.activeBlack};
  ::placeholder {
    color: ${props => props.theme.fontColor.description};
  }
`;

export const ContactMessage = styled.textarea`
  width: 100%;
  min-height: 115px;
  padding: 8px 0px 83px 21px;
  border-radius: 3px;
  border: ${props => props.theme.border.inputBorder};
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: ${props => props.theme.lineHeight.description};
  resize: none;
  color: ${props => props.theme.fontColor.activeBlack};
  ::placeholder {
    color: ${props => props.theme.fontColor.description};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 44px;
  text-align: center;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 40px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 40px;
  }
`;

export const ContactButton = styled(Button)`
  padding: 16px 44px;
  border-radius: 8px;
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.default};
  border: none;
`;
