import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  useEffect
} from "react";

import { Helmet } from "react-helmet";

import PageModal from "../../PageModal/PageModal";
import NoResults from "../../Search/NoResults";
import AssetCategoryDetails from "../CapabilitiesCategoryDetails/CapabilitiesCategoryDetails";
import Strings from "../../../resources/strings";
import Images from "../../../resources/images";
import CardGrid from "../../CommonComponents/CardGrid/CardGrid";
import AssetCard from "../../Cards/CapabilitiesCard/CapabilitiesCard";
import TitleDropdown from "../../CommonComponents/TitleDropdown/TitleDropdown";
import { PageContainer } from "../../../styles/commonStyles";
import Pagination from "../../Pagination/Pagination";
import { StateContext } from "../../../providers/StateContext";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { postApiCall } from "../../../services/api";
import { getUserId } from "../../../utils/commonUtils";

/**
 * Used to get the Assets landing page layout
 * @returns Assets()
 */
export const Assets = () => {
  const state = useContext(StateContext);
  const {
    capabilitiesCategory,
    providers,
    uniqueCapabilitiesCategory,
    lookups
  } = state;
  const capabilitiesCategoryMaster = lookups.capabilitiesCategory;
  const capabilitiesSubCategoryLookupIds =
    lookups.capabilitiesSubCategoryLookupId;
  const [uniqueCapabilities, setUniqueCapabilities] = useState([
    ...state.uniqueCapabilities
  ]);
  const [selectedCapability, setSelectedCapability] = useState(null);

  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  const handleModal = selectedCapability => {
    selectedCapability && setSelectedCapability(selectedCapability);
    setShowModal(true);
  };
  const closeModal = () => {
    modalRef.current.classList.remove("fade");
    setShowModal(false);
  };

  const pageConfig = {
    pageSize: 12,
    pageLimit: 6
  };
  const pagePrevState = window.sessionStorage.getItem(Strings.capabilities);
  const [currentPage, setCurrentPage] = useState(
    pagePrevState?.currentPage || 1
  );
  const [currentPageSeries, setCurrentPageSeries] = useState(
    pagePrevState?.currentPageSeries || 1
  );
  var filterArray = uniqueCapabilities.slice(
    currentPage * pageConfig.pageSize - pageConfig.pageSize,
    currentPage * pageConfig.pageSize
  );
  const [filteredCapability, setFilteredCapability] = useState(filterArray);

  /**
   * updatePageData on filter logic of pagination
   * @param {Array} CapabilityData
   * @param {Number} currentPage
   * @param {Number} currentPageSeries
   */
  const updatePageData = (capabilityData, currentPage, currentPageSeries) => {
    setCurrentPageSeries(currentPageSeries);
    setCurrentPage(currentPage);
    setFilteredCapability(capabilityData);
    window.sessionStorage.setItem(
      Strings.capabilities,
      JSON.stringify({
        currentPage: currentPage,
        currentPageSeries: currentPageSeries
      })
    );
  };

  const [currentCapabilitiesCategory, setCurrentCapabilitiesCategory] =
    useState(Strings.technology);
  const [capabilitiesFilterObject, setCapabilitiesFilterObject] = useState({
    subCategory: []
  });

  const updateFilter = data => {
    setCapabilitiesFilterObject({ ...data });
    let filteredData = [];

    let checkCategory = (item, type = "") => {
      let filtered = data.subCategory.filter(val =>
        type ? val.category === item : val.subCategory === item
      );
      return filtered.length ? true : false;
    };
    currentCapabilitiesCategory !== Strings.technology &&
      !checkCategory(currentCapabilitiesCategory, Strings.category) &&
      setCurrentCapabilitiesCategory(Strings.technology);

    if (!data.subCategory.length) {
      filteredData = [...state.uniqueCapabilities];
    } else {
      Object.keys(uniqueCapabilitiesCategory).forEach(id => {
        uniqueCapabilitiesCategory[id].forEach(
          item => checkCategory(item.Subcategory) && filteredData.push(item)
        );
      });
      // Sorting the unique capabilities
      filteredData.sort((cap1, cap2) => {
        if (cap1?.Category < cap2?.Category) {
          return -1;
        }
      });
      setUniqueCapabilities(filteredData);
    }

    setUniqueCapabilities(filteredData);
    setCurrentPage(1);
    setCurrentPageSeries(1);
    const filteredArray = filteredData.slice(
      1 * pageConfig.pageSize - pageConfig.pageSize,
      1 * pageConfig.pageSize
    );
    setFilteredCapability(filteredArray);
  };

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Capabilities | SBG-FRBB</title>
            </Helmet>
            <PageContainer>
              <Fragment>
                <TitleDropdown
                  title={dictionary?.assets || Strings.assets}
                  dropdownText={`${dictionary?.assets || Strings.assets} ${
                    dictionary?.filter || Strings.filter
                  }`}
                  provider={false}
                  updateFilterCallback={updateFilter}
                  capabilitiesFilterObject={capabilitiesFilterObject}
                  setCapabilitiesFilterObject={setCapabilitiesFilterObject}
                  currentCapabilitiesCategory={currentCapabilitiesCategory}
                  setCurrentCapabilitiesCategory={
                    setCurrentCapabilitiesCategory
                  }
                />
                {filteredCapability && filteredCapability.length ? (
                  <CardGrid>
                    {filteredCapability?.map((capabilityData, index) => (
                      <AssetCard
                        key={index}
                        providers={providers}
                        capabilitiesCategory={capabilitiesCategoryMaster}
                        capabilities={capabilityData}
                        handleModal={() => {
                          postApiCall.postPopularCards({
                            type: Strings.capabilities,
                            userId: getUserId(),
                            createdTime: capabilityData?.CreatedTime,
                            clickedTime: Date.now(),
                            listId: capabilityData?.listId,
                            itemId: capabilityData?.id
                          });
                          handleModal(
                            capabilitiesCategory[
                              capabilityData?.ProvidersLookupId
                            ][capabilityData.Category]
                          );
                        }}
                        filtered={
                          capabilitiesFilterObject.subCategory.length > 0
                        }
                        data-test="asset-card"
                      />
                    ))}
                  </CardGrid>
                ) : (
                  <NoResults
                    description={
                      dictionary?.noResultsFiltered || Strings.noResultsFiltered
                    }
                    title={
                      dictionary?.noResultsFilteredTitle ||
                      Strings.noResultsFilteredTitle
                    }
                    img={Images.NoFilterResult}
                    width="66px"
                    height="62px"
                    mobWidth="66px"
                    mobHeight="62px"
                  />
                )}
                <Pagination
                  onPageChange={updatePageData}
                  currentPage={currentPage}
                  pageData={uniqueCapabilities}
                  pageSize={pageConfig.pageSize}
                  pageLimit={pageConfig.pageLimit}
                  currentPageSeries={currentPageSeries}></Pagination>
              </Fragment>
            </PageContainer>
            <PageModal
              handleModal={handleModal}
              showModal={showModal}
              closeModal={closeModal}
              modalRef={modalRef}>
              <AssetCategoryDetails
                selectedCapability={selectedCapability}
                closeModal={closeModal}
                capabilitiesCategory={capabilitiesCategoryMaster}
                providers={providers}
                capabilitiesSubCategoryLookupIds={Object.values(
                  capabilitiesSubCategoryLookupIds
                )}
              />
            </PageModal>
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  );
};
