import styled from "styled-components";

import { Button } from "../../../styles/commonStyles";

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  height: 100%;
  justify-content: space-between;
`;

export const FilterContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 62px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 0 10px 96px;
  width: 100%;
  justify-content: flex-start;
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin: 32px 18px 10px 17px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 20px 18px 10px 17px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 2px 18px 10px 17px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h4`
  margin: 0 0 16px 0;
  padding: 0;
  text-align: left;
  color: ${props => props.theme.fontColor.inactiveBlack};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.tertiary};
  line-height: ${props => props.theme.lineHeight.normal};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-width: auto;
    min-height: 32px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  height: 100%;
  & > ol {
    height: calc(100% - 34px);
    width: 100%;
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    width: 165px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    width: 165px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 35%;
    min-width: 35%;
  }
`;

export const List = styled.ol`
  padding-left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge add Firefox  */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
`;

export const ListItem = styled.li`
  text-align: left;
`;

export const ListItemButton = styled(Button)`
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  padding: ${props => (props.country ? "13px 0px" : "14px 5px 11px")};
  width: 100%;
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.select && props.theme.fontWeight.medium};
  line-height: ${props => props.theme.lineHeight.content};
  color: ${props =>
    props.select
      ? props.theme.fontColor.activeBlack
      : props.theme.fontColor.inactiveBlack};
  &:hover {
    border: none;
    color: ${props => props.theme.fontColor.activeBlack};
  }

  & > input[type="checkbox"] {
    width: 18px;
    height: 17px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.secondary};
    padding: 13px 0;
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
  & > :first-child {
    width: 100%;
    margin-left: 0px;
  }
  & > :first-child > * {
    width: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox  */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
  }
`;

export const SelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 58px;
  min-width: 300px;
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin: 0 0 0 58px;
    width: 230px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 0 0 0 24px;
    width: 230px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 0 0 0 24px;
    width: calc(65% - 24px);
    min-width: calc(65% - 24px);
    margin-left: 24px;
  }
`;

export const CategoryName = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 29px);
  margin-left: 16px;
  color: ${props =>
    props.select
      ? props.theme.fontColor.activeBlack
      : props.theme.fontColor.inactiveBlack};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: calc(100% - 20px);
    margin-left: 7px;
    line-height: ${props => props.theme.lineHeight.small};
  }
`;
