import styled from "styled-components";
import { CardSubDescription, CardWrapper } from "../../../styles/commonStyles";

export const QuickTourCardWrapper = styled(CardWrapper)`
  border-radius: 16px;
  margin-left: 15px;
  position: fixed;
  top: ${props =>
    (props.currentCardNo === 1 && "215px") ||
    (props.currentCardNo === 2 && "264px") ||
    (props.currentCardNo === 3 && "312px")};
  left: 185px;
  @media ${props => props.theme.breakPoints.extraLargeDesktopDevice} {
    left: calc((100% - 1680px) / 2 + 173px);
  }
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    left: 160px;
    width: 250px;
    top: ${props =>
      (props.currentCardNo === 1 && "135px") ||
      (props.currentCardNo === 2 && "180px") ||
      (props.currentCardNo === 3 && "230px")};
    & > img {
      height: 110px;
    }
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    left: 160px;
    width: 250px;
    top: ${props =>
      (props.currentCardNo === 1 && "135px") ||
      (props.currentCardNo === 2 && "180px") ||
      (props.currentCardNo === 3 && "230px")};
    & > img {
      height: 110px;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 100%;
    left: 0px;
    top: 240px;
    position: initial;
    margin: 20px 20px 0 20px;
    & > img {
      object-fit: fill;
    }
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    width: 100%;
    left: 0px;
    top: 240px;
    position: initial;
    margin: 20px 20px 0 20px;
    & > img {
      object-fit: fill;
    }
  }
`;

export const SkipWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
  background-color: ${props => props.theme.backgroundColor.primary0};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  :after {
    content: "";
    border-color: transparent ${props => props.theme.backgroundColor.primary0}
      transparent transparent;
    border-style: solid;
    border-width: 14px;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: -25px;
    @media ${props => props.theme.breakPoints.tabDevice} {
      border-width: 0;
      width: 0;
      left: 0;
    }
    @media ${props => props.theme.breakPoints.mobileDevice} {
      border-width: 0;
      width: 0;
      left: 0;
    }
  }
  & > button {
    margin-left: auto;
  }
  & > * {
    padding: 10px 14px;
    border: none;
    min-width: min-content;
    color: ${props => props.theme.fontColor.inactiveBlack};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.tertiary};
    line-height: ${props => props.theme.lineHeight.normal};
    :hover {
      border: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 18px 20px 0px;
  & > :first-child {
    margin-bottom: 12px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  & > * {
    padding: 0;
    min-width: min-content;
    border: none;
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.tertiary};
    line-height: ${props => props.theme.lineHeight.normal};
    color: ${props => props.theme.fontColor.inactiveBlack};
    :hover {
      border: none;
    }
    :disabled {
      border: none;
      color: ${props => props.theme.fontColor.inactiveBlack};
      cursor: default;
    }
  }
  & > :last-child {
    margin-left: 17px;
    color: ${props => props.theme.fontColor.linkColor};
    :disabled {
      color: ${props => props.theme.fontColor.inactiveBlack};
    }
  }
`;

export const CardNo = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.fontColor.activeBlack};
  & > :last-child {
    color: ${props => props.theme.fontColor.inactiveBlack};
  }
`;

export const CurrentCardNo = styled.p`
  margin-bottom: 0;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.tertiary};
  line-height: ${props => props.theme.lineHeight.normal};
`;

export const QuickTourCardTitle = styled(CardSubDescription)`
  margin-bottom: 0;
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.default};
`;

export const QuickTourCardDescription = styled.p`
  margin-bottom: 0;
  min-height: 46px;
  color: ${props => props.theme.fontColor.inactiveBlack};
  font-size: ${props => props.theme.fontSize.secondary};
  line-height: ${props => props.theme.lineHeight.secondary};
`;
