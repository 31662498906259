import styled from "styled-components";

import { TableData, TableRow } from "../../../styles/commonStyles";

export const ContactTableData = styled(TableData)`
  font-size: ${props => props.theme.fontSize.quaternary};
  color: ${props => props.theme.fontColor.activeBlack};
  min-width: 249px;
  vertical-align: top;
  :first-child {
    padding: 12px 23px 15px 0;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    :first-child {
      min-width: 122px;
      padding: 6px 5px 15px 0;
    }
    :last-child {
      min-width: 212px;
      padding: 6px 0 0 5px;
      font-size: ${props => props.theme.fontSize.tertiary};
    }
  }
`;
export const ContactTableRow = styled(TableRow)`
  td:first-child {
    padding-left: 0;
  }
`;

export const MainText = styled.div`
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const SubText = styled.div`
  color: ${props => props.theme.fontColor.inactiveBlack};
  font-size: ${props => props.theme.fontSize.tertiary};
`;
