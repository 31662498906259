import React from "react";
import PropTypes from "prop-types";

import Images from "../../../resources/images";
import Strings from "../../../resources/strings";
import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import { convertToUpperCase } from "../../../utils/commonUtils";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import { Button } from "../../../styles/commonStyles";
import {
  QuickTourCardWrapper,
  SkipWrapper,
  Content,
  ButtonsWrapper,
  Footer,
  CurrentCardNo,
  CardNo,
  QuickTourCardTitle,
  QuickTourCardDescription
} from "./style";

/**
 * QuickTourCard component will use for different pages introduction
 * @param {bool} prevBtnDisable - defines previous button disabled or not
 * @param {bool} nextBtnDisable - defines previous button disabled or not
 * @param {string} img
 * @param {string} title - title for card
 * @param {string} description - description for card
 * @param {number} currentCardNo - current card numer out of total cards
 * @param {number} totalCards - defines total cards number
 * @param {func} closeModal - defines tour finished or not
 * @param {func} handleTour - onClick next button it will go to next stage
 * @param {func} handlePrevTour - onClick prev button it will go to previous state
 * @returns
 */
const QuickTourCard = props => {
  const {
    prevBtnDisable,
    nextBtnDisable,
    img,
    title,
    description,
    currentCardNo,
    totalCards,
    closeModal,
    handleTour,
    handlePrevTour
  } = props;
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <QuickTourCardWrapper currentCardNo={currentCardNo}>
            <SkipWrapper>
              <Button onClick={closeModal}>
                {convertToUpperCase(dictionary?.skip || Strings.skip)}
              </Button>
            </SkipWrapper>
            <ImageCard
              img={img}
              width="100%"
              height="172px"
              mobHeight="208px"
              mobWidth="100%"
              alt="bannerImg"
            />
            <Content>
              <QuickTourCardTitle>{title}</QuickTourCardTitle>
              <QuickTourCardDescription>{description}</QuickTourCardDescription>
              <Footer>
                <CardNo>
                  <CurrentCardNo>{currentCardNo}/</CurrentCardNo>
                  <CurrentCardNo>{totalCards}</CurrentCardNo>
                </CardNo>
                <ButtonsWrapper>
                  <Button disabled={prevBtnDisable} onClick={handlePrevTour}>
                    {convertToUpperCase(dictionary?.prev || Strings.prev)}
                  </Button>
                  <Button disabled={nextBtnDisable} onClick={handleTour}>
                    {convertToUpperCase(dictionary?.next || Strings.next)}
                  </Button>
                </ButtonsWrapper>
              </Footer>
            </Content>
          </QuickTourCardWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

QuickTourCard.propTypes = {
  prevBtnDisable: PropTypes.bool,
  nextBtnDisable: PropTypes.bool,
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  currentCardNo: PropTypes.number,
  totalCards: PropTypes.number,
  closeModal: PropTypes.func,
  handleTour: PropTypes.func,
  handlePrevTour: PropTypes.func
};

QuickTourCard.defaultProps = {
  prevBtnDisable: false,
  nextBtnDisable: false,
  img: Images.BannerImg,
  title: "Discover engaging features in explore page",
  description:
    "It allow for easier cross - department engagement by showcasing popular providers profiles, capabilities and case studies.",
  currentCardNo: 1,
  totalCards: 3
};

export default QuickTourCard;
