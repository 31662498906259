import React from "react";
import PropTypes from "prop-types";

import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  convertToUpperCase,
  getRefactoredImageUrl
} from "../../../utils/commonUtils";
import {
  CaseStudyCardWrapper,
  CaseStudyCardImageWrapper,
  CaseStudyCardContent,
  CaseStudyCardSubDescription,
  CaseStudyCardDate
} from "./style";

/**
 * CaseStudyCard component defines different topics case study overview
 * @param {string} caseStudy - defines description of case study
 * @param {func} handleModal - callback function to open the popup modal for details
 * @example
 * CaseStudyCard({title, description, date})
 */
const CaseStudyCard = props => {
  const { caseStudy, handleModal } = props;
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <CaseStudyCardWrapper
            onClick={handleModal}
            role="button"
            tabIndex="0"
            onKeyPress={evt => evt.key === "Enter" && handleModal}>
            <CaseStudyCardImageWrapper
              bannerImg={getRefactoredImageUrl(dictionary?.BannerImg)}>
              {convertToUpperCase(caseStudy?.Subcategory)}
            </CaseStudyCardImageWrapper>
            <CaseStudyCardContent>
              <CaseStudyCardSubDescription>
                {caseStudy?.Title}
              </CaseStudyCardSubDescription>
              <CaseStudyCardDate>{caseStudy?.Created}</CaseStudyCardDate>
            </CaseStudyCardContent>
          </CaseStudyCardWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

CaseStudyCard.propTypes = {
  caseStudy: PropTypes.object,
  handleModal: PropTypes.func
};

export default CaseStudyCard;
