import styled from "styled-components";

import { PageContainer } from "../../../styles/commonStyles";

export const DetailsPageContainer = styled(PageContainer)`
  transform: translateY(-150px);
  padding-right: 142px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 0 20px 20px;
    transform: translateY(-60px);
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: 0 20px 20px;
  }
`;

export const ProviderDetailsWrapper = styled.div`
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 16px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 16px;
  }
`;
