import styled from "styled-components";
import { CardButton } from "../../../styles/commonStyles";

export const CardWrapper = styled.div`
  display: flex;
  border-radius: 16px;
  box-shadow: ${props => props.theme.shadow.card};
  border: solid 1px #ebebeb;
  padding: 23px 12px 23px 22px;
  margin: 10px 12px 10px 0px;
  width: 280px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 15px 17px 26px 16px;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: 15px 17px 26px 16px;
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 50%;
  background-color: #00a1e0;
  width: 46px;
  height: 46px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${props => props.theme.breakPoints.tabDevice} {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-right: 10px;
    & > img {
      max-width: 18px;
      max-height: 18px;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-right: 10px;
    & > img {
      max-width: 18px;
      max-height: 18px;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSize.quaternary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  @media ${props => props.theme.breakPoints.tabDevice} {
    font-size: ${props => props.theme.fontSize.primary};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.primary};
  }
`;

export const Content = styled.p`
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.fontColor.inactiveBlack};
  margin: 0;
`;

export const CapabilityContentButton = styled(CardButton)`
  @media ${props => props.theme.breakPoints.mobileDevice} {
    max-width: calc(50% - 5px);
    min-width: calc(50% - 5px);
    min-height: 110px;
    margin-bottom: 10px;
    & :nth-child(2n) {
      margin: 0 0px 10px 10px;
    }
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    max-width: calc(50% - 5px);
    min-width: calc(50% - 5px);
    min-height: 92x;
    margin-bottom: 10px;
    & :nth-child(2n) {
      margin: 0 0px 10px 10px;
    }
  }
  @media ${props => props.theme.breakPoints.smallMobileDevice} {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 120px;
    margin-bottom: 10px;
    & :nth-child(2n) {
      margin: 0 0 10px 0;
    }
  }
`;
