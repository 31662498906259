import React, { useEffect } from "react";
import PropTypes from "prop-types";

import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import { IconTextCardWrapper, Title , Description} from "./style";

/**
 * QuickTourCard component shows quick tour finished.
 * @param {func} handleCloseModal - handle when needs to close Popup Modal
 * @param {string} title
 * @param {string} description
 * @param {string} image
 * @param {string} height
 * @param {string} width
 * @param {string} mobWidth
 * @param {string} mobHeight
 * @example
 * QuickTourCardEnd()
 */
const IconTextCard = props => {
  const { handleCloseModal, title ,  description, image,  height, width, mobWidth, mobHeight} = props;

  useEffect(() => {
    setTimeout(handleCloseModal, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconTextCardWrapper>
        <ImageCard
          img={image}
          height={height}
          width={width}
          mobWidth={mobWidth}
          mobHeight={mobHeight}
        />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </IconTextCardWrapper>
  );
};

IconTextCard.propTypes = {
  handleCloseModal: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  image:PropTypes.string,
  width:PropTypes.string,
  height:PropTypes.string,
  mobHeight:PropTypes.string,
  mobWidth:PropTypes.string,
};

export default IconTextCard;
