import styled from "styled-components";
import { Modal } from "react-bootstrap";

import { fadeInAnimation } from "../../styles/commonStyles";

export const ModalWrapper = styled.div``;

export const CustomModal = styled(Modal)`
  animation-name: ${fadeInAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  display: flex;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    animation-name: none;
  }
  &.fade {
    transition: none;
  }
  .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
  &.fade.show .modal-dialog {
    transition: none;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      bottom: 0px;
      transition: all 0.3s ease-in-out;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    background-color: ${props => props.theme.backgroundColor.transparent};
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
  }
  .modal-body {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox  */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
  }
`;
