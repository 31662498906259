import React, { Fragment, useState, useEffect, useContext } from "react";

import { Helmet } from "react-helmet";
import { useQueryParam } from "use-query-params";

import Strings from "../../../resources/strings";
import Images from "../../../resources/images";
import CardGrid from "../../CommonComponents/CardGrid/CardGrid";
import ProviderCard from "../../Cards/ProviderCard/ProviderCard";
import TitleDropdown from "../../CommonComponents/TitleDropdown/TitleDropdown";
import NoResults from "./../../Search/NoResults";
import { PageContainer } from "../../../styles/commonStyles";
import { StateContext } from "../../../providers/StateContext";
import { DictionaryContext } from "../../../providers/DictionaryContext";

/**
 * Used to get the Providers landing page layout
 * @returns Providers()
 */
export const Providers = () => {
  const state = useContext(StateContext);
  const [providers, setProviders] = useState(state.providers);
  const [filteredProvider, setFilteredProvider] = useState(providers);
  const [filterObject, setFilterObject] = useState({
    Category: [],
    Industry: [],
    Location: []
  });
  let filterParam = useQueryParam("filter")[0];

  useEffect(() => {
    if (filterParam) {
      setFilterObject({
        Category: [filterParam],
        Industry: [],
        Location: []
      });
      let filteredResults = providers.filter(
        providerData => providerData?.CategoryArray?.indexOf(filterParam) !== -1
      );
      setProviders(filteredResults);
      setFilteredProvider(filteredResults);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      filterParam = null;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [filterParam]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const isFilteredCategory = (provider, selectedCategory) => {
    return (
      selectedCategory.filter(category => provider.indexOf(category) !== -1)
        .length > 0
    );
  };

  const applyFilter = filteredData => {
    setFilterObject({ ...filteredData });
    selectedCategory !== Strings.industry &&
      !filteredData[selectedCategory]?.length &&
      setSelectedCategory(Strings.industry);
    const filterData = state.providers.filter(
      data =>
        (!filteredData.Category?.length ||
          isFilteredCategory(data?.CategoryArray, filteredData.Category)) &&
        (!filteredData.Industry?.length ||
          filteredData.Industry.includes(
            data?.ComputedField_Industry?.trim()
          )) &&
        (!filteredData.Location?.length ||
          filteredData.Location.includes(data?.Country?.trim()))
    );
    setProviders(filterData);
    setFilteredProvider(filterData);
  };
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Providers | SBG-FRBB</title>
      </Helmet>
      <DictionaryContext.Consumer>
        {({ dictionary }) => {
          return (
            <PageContainer>
              <TitleDropdown
                title={dictionary?.providers || Strings.providers}
                provider={true}
                dropdownText={`${dictionary?.providers || Strings.providers} ${
                  dictionary?.filter || Strings.filter
                }`}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                updateFilterCallback={applyFilter}
                filterParam={filterParam}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                data-test="title-dropdown"
              />
              {filteredProvider && filteredProvider.length > 0 ? (
                <CardGrid>
                  {filteredProvider.map((providerData, index) => {
                    return (
                      <ProviderCard
                        provider={providerData}
                        headingBgColor={true}
                        key={index}
                        redirectTo={
                          "/auth/providers/details?id=" + providerData?.id
                        }></ProviderCard>
                    );
                  })}
                </CardGrid>
              ) : (
                <NoResults
                  description={
                    dictionary?.noResultsFiltered || Strings.noResultsFiltered
                  }
                  title={
                    dictionary?.noResultsFilteredTitle ||
                    Strings.noResultsFilteredTitle
                  }
                  img={Images.NoFilterResult}
                  width="66px"
                  height="62px"
                  mobWidth="66px"
                  mobHeight="62px"
                />
              )}
            </PageContainer>
          );
        }}
      </DictionaryContext.Consumer>
    </Fragment>
  );
};
