import styled from "styled-components";

import Slider from "react-slick";

export const Carousel = styled(Slider)`
  width: 100%;
  height: 440px;
  margin: 16px 0 56px;
  border-radius: 20px;
  overflow: hidden;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    height: 500px;
    margin: 16px 0 32px;
  }
  &:before {
    content: "";
    opacity: 0.3;
    border-radius: 32.5px;
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
  .slick-list {
    border-radius: 20px;
  }
  .slick-arrow {
    display: none !important;
  }
  div:focus {
    outline: none;
  }
  .slick-dots {
    right: 32px;
    bottom: 34px;
    width: auto;
    li {
      width: auto;
      height: auto;
      button {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        opacity: 0.5;
        background-color: white;
        padding: 0;
        &:before {
          display: none;
        }
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }
      }
      &.slick-active {
        button {
          width: 10px;
          height: 10px;
          opacity: 1;
        }
      }
    }
  }
`;
