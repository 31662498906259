import React from "react";
import { PropTypes } from "prop-types";

import CustomLink from "../CommonComponents/Link/Link";
import Strings from "../../resources/strings";
import { DictionaryContext } from "../../providers/DictionaryContext";
import {
  ExploreCard,
  ExploreCardTitle,
  ExploreCardSubTitle,
  ExploreCategoryWrapper
} from "./style";

/**
 * ExploreCategory component is used to render the cards with categories to explore.
 * @param {*} redirectTo
 * @param {*} title
 * @param {Object} categoryCount
 * @example
 * ExploreCategory({ redirectTo, title, subTitle })
 */
const ExploreCategory = props => {
  const { redirectTo, title, categoryCount } = props;
  return (
    categoryCount > 0 && (
      <ExploreCategoryWrapper>
        <CustomLink to={redirectTo}>
          <ExploreCard>
            <ExploreCardTitle>{title}</ExploreCardTitle>
            <DictionaryContext.Consumer>
              {({ dictionary }) => {
                return (
                  <ExploreCardSubTitle>
                    {categoryCount +
                      " " +
                      (categoryCount > 1
                        ? dictionary?.providers || Strings.providers
                        : dictionary?.provider || Strings.provider)}
                  </ExploreCardSubTitle>
                );
              }}
            </DictionaryContext.Consumer>
          </ExploreCard>
        </CustomLink>
      </ExploreCategoryWrapper>
    )
  );
};

ExploreCategory.propTypes = {
  redirectTo: PropTypes.string,
  title: PropTypes.string,
  categoryCount: PropTypes.number
};

export default ExploreCategory;
