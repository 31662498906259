import styled from "styled-components";
import { CardSubTitle, CardWrapper } from "../../styles/commonStyles";

export const ExploreCategoryWrapper = styled.div`
  width: 226px;
  margin-right: 20px;
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 6px;
  }
  @media ${props => props.theme.breakPoints.extraLargeDesktopDevice} {
    margin-right: 20px !important;
  }
  @media ${props => props.theme.breakPoints.xlMediumDesktopDevice} {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  @media ${props => props.theme.breakPoints.xlDesktopDevice} {
    margin-right: 20px !important;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: calc(50% - 10px);
    margin-right: 10px;
    &:nth-child(-n + 2) {
      margin-top: 6px;
    }
    ${CardWrapper} {
      padding: 20px 11px 20px 11px;
      @media ${props => props.theme.breakPoints.smallMobileDevice} {
        min-height: 140px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
      }
    }
  }
`;

export const ExploreCard = styled(CardWrapper)`
  width: 100%;
  border-radius: 16px;
  box-shadow: ${props => props.theme.boxShadow.card};
  background-color: ${props => props.theme.backgroundColor.card};
  padding: 20px;
  margin-right: 0px;
  &:hover {
    background-color: ${props => props.theme.backgroundColor.blue};
  }
`;

export const ExploreCardTitle = styled.p`
  font-size: ${props => props.theme.fontSize.quaternary};
  line-height: ${props => props.theme.lineHeight.description};
  color: ${props => props.theme.fontColor.activeBlack};
  font-weight: ${props => props.theme.fontWeight.bolder};
  margin: 0 0 5px;
  min-height: 48px;
  ${ExploreCard}:hover & {
    color: ${props => props.theme.fontColor.primary};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.primary};
  }
`;
export const ExploreCardSubTitle = styled(CardSubTitle)`
  font-size: ${props => props.theme.fontSize.secondary};
  line-height: ${props => props.theme.lineHeight.default};
  ${ExploreCard}:hover & {
    color: ${props => props.theme.fontColor.primary};
  }
`;
