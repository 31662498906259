import React, { useRef, useState, Fragment } from "react";
import { PropTypes } from "prop-types";

import CaseStudyCard from "../../Cards/CaseStudyCard/CaseStudyCard";
import PageModal from "../../PageModal/PageModal";
import CaseStudyDetails from "../../Pages/CaseStudyDetails/CaseStudyDetails";
import { CaseStudyContentWrapper } from "./style";

/**
 * CaseStudyContent component will provides different kind of cases information
 * @param {Array} caseStudies
 * @example
 * CaseStudyContent()
 */
const CaseStudyContent = ({ caseStudies }) => {
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCaseStudy, setSelectedCaseStudy] = useState(null);

  const handleModal = selectedData => {
    selectedData && setSelectedCaseStudy(selectedData);
    showModal && closeModal();
    setTimeout(() => {
      setShowModal(true);
    }, 600);
  };
  const closeModal = () => {
    modalRef.current.classList.remove("fade");
    setShowModal(false);
  };
  return (
    <Fragment>
      <CaseStudyContentWrapper>
        {caseStudies?.map((caseStudy, index) => {
          return (
            <CaseStudyCard
              key={index}
              caseStudy={caseStudy}
              handleModal={() => handleModal(caseStudy)}
            />
          );
        })}
      </CaseStudyContentWrapper>
      <PageModal
        filter="true"
        hideBtn={true}
        handleModal={handleModal}
        showModal={showModal}
        closeModal={closeModal}
        modalRef={modalRef}
        data-test="modal-click">
        <CaseStudyDetails
          closeModal={closeModal}
          handleModal={handleModal}
          selectedCaseStudy={selectedCaseStudy}
        />
      </PageModal>
    </Fragment>
  );
};

CaseStudyContent.propTypes = {
  caseStudies: PropTypes.array
};

export default CaseStudyContent;
