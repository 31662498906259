import React from "react";

import Strings from "../../../resources/strings";
import SliderComponent from "../../SliderCarousel/SliderCarousel";
import IconTextButton from "../../CommonComponents/IconTextButton/IconTextButton";
import { FeaturedCaseStudy } from "../../../styles/commonStyles";
import RichText from "../../CommonComponents/RichText/RichText";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  convertToUpperCase,
  fetchNameById,
  getRefactoredImageUrl
} from "../../../utils/commonUtils";
import {
  ExploreContainer,
  ExploreContentWrapper,
  ExploreImageWrapper,
  CaseStudyTitle,
  CaseStudyDescription,
  ExploreBottomWrapper,
  CaseStudyImgTextWrapper,
  CaseStudyImgWrapper,
  CaseStudyTextWrapper,
  ExploreContent
} from "./style";

/**
 * Explore banner card to show the case study details
 * @param {func} handleModal
 * @param {Array} featuredCaseStudy
 * @param {Array} providers
 * @returns Card component
 */
const ExploreBannerCard = ({
  handleModal,
  featuredCaseStudy,
  providers,
  slider
}) => {
  const play = () => {
    slider.current && slider.current.slickPlay();
  };
  const pause = () => {
    slider.current && slider.current.slickPause();
  };
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <SliderComponent sliderRef={slider}>
            {featuredCaseStudy.map((caseStudy, index) => {
              const caseStudyProvider = fetchNameById(
                providers,
                caseStudy["ProvidersLookupId"],
                dictionary?.companyId || Strings.companyId
              )[0];
              return (
                <ExploreContainer
                  key={index}
                  onTouchStart={pause}
                  onTouchEnd={play}
                  data-test="touch-Slider">
                  <ExploreImageWrapper>
                    {" "}
                    <img
                      src={getRefactoredImageUrl(dictionary?.BannerImg)}
                      alt="banner"></img>
                  </ExploreImageWrapper>
                  <ExploreContent>
                    <ExploreContentWrapper>
                      <FeaturedCaseStudy>
                        {convertToUpperCase(
                          dictionary?.featuredCaseStudy ||
                            Strings.featuredCaseStudy
                        )}
                      </FeaturedCaseStudy>
                      <ExploreBottomWrapper>
                        <CaseStudyImgTextWrapper>
                          <CaseStudyImgWrapper>
                            <img
                              src={getRefactoredImageUrl(
                                caseStudyProvider?.Company_x0020_Logo?.Url
                              )}
                              alt="CaseStudyImage"></img>
                          </CaseStudyImgWrapper>
                          <CaseStudyTextWrapper>
                            {caseStudyProvider?.Title}
                          </CaseStudyTextWrapper>
                        </CaseStudyImgTextWrapper>
                        <CaseStudyTitle>{caseStudy?.Title}</CaseStudyTitle>
                        <CaseStudyDescription>
                          <RichText>{caseStudy?.Abstract}</RichText>
                        </CaseStudyDescription>
                        <IconTextButton
                          onClick={() =>
                            handleModal(
                              dictionary?.caseStudies || Strings.caseStudies,
                              caseStudy
                            )
                          }
                          linkText={
                            dictionary?.viewCaseStudy || Strings.viewCaseStudy
                          }
                          data-test="icon-text"></IconTextButton>
                      </ExploreBottomWrapper>
                    </ExploreContentWrapper>
                  </ExploreContent>
                </ExploreContainer>
              );
            })}
          </SliderComponent>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

export default ExploreBannerCard;
